import styles from "./SchoolContent4.module.css";

const SchoolContent4 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            GenNEP Learning App - Students
          </div>
          <div className={styles.getThemReady}>
            The GenNEP online learning app is truly one-of-a-kind! It combines the best of both traditional and modern learning techniques to give students an exceptional educational experience. With hassle-free access to live classes and engaging doubt-solving sessions, students can confidently reach their goals! A one stop solution for all learning needs.
          </div>
          <div className={styles.LearningAppTypeBox}>
            <div className={styles.LearningAppTypeBox1}>
              <img
                alt=""
                src="/greencheck.svg"
              />
              <span className={styles.LearningAppTypeBoxText}>2 Million+ Learning Resources</span>
            </div>
            <div className={styles.LearningAppTypeBox2}>
              <img
                alt=""
                src="/greencheck.svg"
              />
              <span className={styles.LearningAppTypeBoxText}>3000+ Publishers Mapped for Personalized Learning</span>
            </div>
          </div>
          <div className={styles.LearningAppTypeBox}>
            <div className={styles.LearningAppTypeBox1}>
              <img
                alt=""
                src="/greencheck.svg"
              />
              <span className={styles.LearningAppTypeBoxText}>Quizes and Mentor/Teacher Help</span>
            </div>
          </div>
          <a href="LearningApp" style={{ textDecoration: 'none',textAlign:'center',width:'100%' }}>
            <div style={{ width: '190px' }} className={styles.a}>
              <div style={{ fontSize: '18px' }} className={styles.startLearning}>Learn More</div>
            </div>
          </a>
        </div>


        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/schoolimg3.png"
          />
        </div>

      </div>
    </div>
  );
};

export default SchoolContent4;
