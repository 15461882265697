import styles from "./PartnerContent2.module.css";

const PartnerContent2 = () => {
  
   return (
     <div className={styles.division1}>
         <p className={styles.division1Text}>
            Why Genxture
         </p>    
        <p className={styles.division1Title}>
          We are Making Education impactful and affordable.
         </p>  
         <p className={styles.division1Text}>
          At Genxture we envision to take new era education to its pinnacle by leveraging digital technology focused at enabling students learn by doing to discover their natural talent and untapped potential through socio emotional learning.
         </p>       
     </div>
   );

};

export default PartnerContent2;
