     import styles from "./TtpContent3.module.css";

const TtpContent3 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>



        <div className={styles.div}>

          <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/ttpContent.png"
          />
        </div>
          <div className={styles.helpYourChild}>
            Making Teachers leaders of learning
          </div>
          <div className={styles.getThemReady}>
            Genxture Teacher Training Program means to enable teachers to learn about what they can do to be great influencers using
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/ttpicon1.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Self awareness to become observant of the self & the learners</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/ttpicon2.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Self-Driven Attitude to be responsible about self learning</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/ttpicon3.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Future Competency to learn teaching-learning competencies</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/ttpicon4.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Enabling Learning Communities to self-sustaining learning groups </span>
               </div>
            </div>


        </div>

      


      </div>
    </div>
  );
};

export default TtpContent3;
