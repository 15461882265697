import styles from "./LearningAppContent2.module.css";

const LearningAppContent2 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/LearningAppPhone1.svg"
          />
        </div>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            K12 Learning Experience
          </div>
          <div className={styles.getThemReady}>
            We have combined everything in a bundle for your seamless learning experience. We make sure you don't lose a single aspect of education while with us.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/LearningAppIcon-1.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Daily assignments.</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/LearningAppIcon-2.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Assessment scores</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/LearningAppIcon-3.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>  MIND Activities</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/LearningAppIcon-4.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Tips and tricks </span>
               </div>
            </div>

        </div>

        
      </div>
    </div>
  );
};

export default LearningAppContent2;
