import Meta from "../components/Meta";
import Header from "../components/Header";
import CommonContent1 from "../components/CommonContent1";
import CommonContent2 from "../components/CommonContent2";
import CommonContent3 from "../components/CommonContent3";
import CommonContent4 from "../components/CommonContent4";
import CommonContent5 from "../components/CommonContent5";
import ParentsTestimonialSlider from "../components/ParentsTestimonialSlider";
import CommonContent6 from "../components/CommonContent6";

import Footer from "../components/Footer";
import styles from "./Parents.module.css";

const Parents = () => {

  const images = ['/aboutusperson1.png', '/aboutusperson2.png', '/aboutusperson3.png'];
  const content = ['<img src="/quotes.svg" style="height: 52px; width: 52px; overflow: hidden;"><br><img src="/group-861.svg" style="height: 24px; width: 152px; overflow: hidden;"><div className={styles.text}>This is what we should really teach our kids. Very specific to the current challenges of kids ..i found tools provided are practical and effective.</div><br><div style="font-size:44px; font-family: Inter; font-style: normal; opacity: 10; font-weight: bold;">Father of Aarav</div><div style="font-size:20px; font-family: Inter; font-style: normal; opacity: 10;">Class 6 | Kolkata</div>',
                   '<img src="/quotes.svg" style="height: 52px; width: 52px; overflow: hidden;"><br><img src="/group-861.svg" style="height: 24px; width: 152px; overflow: hidden;"><div className={styles.text}>This is what we should really teach our kids. Very specific to the current challenges of kids .Thanks to the team to come up with powerful session.i found tools provided are practical and effective.</div><br><div style="font-size:44px; font-family: Inter; font-style: normal; opacity: 10; font-weight: bold;">Father of Jatin</div><div style="font-size:20px; font-family: Inter; font-style: normal; opacity: 10;">Class 9 | Mumbai</div>',
                   '<img src="/quotes.svg" style="height: 52px; width: 52px; overflow: hidden;"><br><img src="/group-861.svg" style="height: 24px; width: 152px; overflow: hidden;"><div className={styles.text}>This is what we should really teach our kids. Very specific to the current challenges of kids .Thanks to the team to come up with powerful session.</div><br><div style="font-size:44px; font-family: Inter; font-style: normal; opacity: 10; font-weight: bold;">Father of Nikita</div><div style="font-size:20px; font-family: Inter; font-style: normal; opacity: 10;">Class 7 | Delhi</div>'];

  return (

    <div className={styles.home}>
      <div className={styles.hero}>
        <img className={styles.maskGroupIcon} alt="" src="/mask-group@2x.webp" />
        <img className={styles.frameIcon} alt="" src="/frame.svg" />
        <Header />
        <CommonContent1 />
      </div>
      <CommonContent2 />
      <CommonContent3 />
      <CommonContent4 />
      <CommonContent5 />
      <div className={styles.divSliderHead}>
        <div className={styles.whatParentsSay}>What Parents say about Us</div>
      </div>
      <ParentsTestimonialSlider images={images} content={content} />
      <CommonContent6 />
      <Footer />
    </div>
  );
};

export default Parents;
