import styles from "./HomeContent7.module.css";

import React,{useState} from 'react'


const HomeContent7 = () => {


  return (

    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/union2.svg" />
      <img className={styles.unionIcon2} alt="" src="/union4.svg" />
      <div className={styles.div}>
        <div className={styles.whyGenxture}>Genxture Proves It's worth- Tangible Outcomes</div>
      </div>

    <div className={styles.div0}>
      <div className={styles.div1}>
        <div className={styles.div2}>

           <div className={styles.connect}>Resilience</div>
           <div className={styles.buildTrustingRelationships}>
             Kids learn to manage anxiety by identifying and challenging negative thoughts through techniques like recognizing, reflecting and resolving  them.
           </div>


        <div className={styles.div3}>1</div>
        </div>
        <div tabIndex="5" className={styles.div4}>
          <div className={styles.div02}>        
            <h1 className={styles.connectHide}>Mindfulness</h1>
              <div className={styles.buildTrustingRelationshipsHide}>
                <p className={styles.homeContentOpacity}>Kids learn to focus on the present moment and observe their thoughts and feelings without judgment.</p>
              </div>
             </div> 
          <div className={styles.div5}>2</div>
        </div>
        <div tabIndex="6" className={styles.div6}>
          <div className={styles.div02}>        
            <h1 className={styles.connectHide}>Emotional regulation</h1>
              <div className={styles.buildTrustingRelationshipsHide}>
               <p className={styles.homeContentOpacity}>Kids learn understand and manage their emotions in a healthy and constructive way.</p>
            </div>
          </div>  
          <div className={styles.div7}>3</div>
        </div>
        <div tabIndex="7" className={styles.div8}>
           <div className={styles.div02}>        
            <h1 className={styles.connectHide}>Impulse Control</h1>
              <div className={styles.buildTrustingRelationshipsHide}>
               <p className={styles.homeContentOpacity}>Kids learn learn to take pause and think before reacting to a trigger or event, enabling them to respond in a more thoughtful and intentional manner.</p>
            </div>
           </div> 
          <div className={styles.div9}>4</div>
        </div>
        <div tabIndex="8" className={styles.div10}>
          <div className={styles.div02}>        
            <h1 className={styles.connectHide}>Coping skills</h1>
              <div className={styles.buildTrustingRelationshipsHide}>
               <p className={styles.homeContentOpacity}>kids learn to techniques and strategies to deal with challenging situations throgh animation , worksheet, reflection question , guided visualization and interactive practise</p>
            </div>
          </div>  
          <div className={styles.div11}>5</div>
        </div>

        <div className={styles.div41}>
          <div className={styles.div5}>1</div>
        </div>
        <div className={styles.div62}>
          <div className={styles.div7}>1</div>
        </div>
        <div className={styles.div83}>
          <div className={styles.div9}>1</div>
        </div>
        <div className={styles.div104}>
          <div className={styles.div11}>1</div>
        </div>


      </div>
    </div>
    </div>
  );
};

export default HomeContent7;
