import styles from "./AboutContent02.module.css";

const AboutContent02 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>

        <div className={styles.div}>

          <div className={styles.getThemReady}>

  <div className={styles.threeboxesContainerMain}>
    <div className={styles.threeboxes}>
       <div className={styles.threeboxesContainer}>
         <div className={styles.threeboxesContainerBox}>

          <div className={styles.threeboxesContainerBoxCircle1}>
            <h1>WHY</h1>
            <p>We Exist</p>
          </div>

         </div>
         <div className={styles.threeboxesContainerBox}>
          <div className={styles.threeboxesContainerBoxCircle2}>
            <h1>WHAT</h1>
            <p>We aim to achieve</p>
          </div>
         </div>
         <div className={styles.threeboxesContainerBox}>

          <div className={styles.threeboxesContainerBoxCircle3}>
            <h1>HOW</h1>
            <p>We plan to achieve our vision</p>
          </div>

         </div>
       </div>
    </div> 

    <div className={styles.threeboxes}>
       <div className={styles.threeboxesContainer}>
         <div className={styles.threeboxesContainerBox}>
            <p><b>Our Purpose</b></p>
            <span>We exist to make individual the best version of themselves</span>
         </div>
         <div className={styles.threeboxesContainerBox}>
            <p><b>Our Vision</b></p>
            <span>Create a world where mental health care that is stigma-free, inclusive, and empowering for all</span>
         </div>
         <div className={styles.threeboxesContainerBox}>
            <p><b>Our Mission</b></p>
             <span>By making education impactful and affordable.</span>
         </div>
       </div>
    </div> 
 </div>

                  <img className={styles.threeboxesContainerMainImg}
                   alt=""
                   src="/aboutimage111.svg"
                  />

          </div>

        </div>
      </div>
    </div>
  );
};

export default AboutContent02;
