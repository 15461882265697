import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./global.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
  <a href="https://api.whatsapp.com/send/?phone=919380203885&text=Hello+Genxture+Team%21&type=phone_number&app_absent=0" style={{position:"fixed",top:"80%",right:0,zIndex:1000}}>
    <img src="/WhatsApp.png"  />
  </a>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
