import styles from "./PartnerContent4.module.css";

const PartnerContent4 = () => {
  return (
    <div className={styles.partnerDivParent}>

      <div className={styles.partnerDiv}>
        <div className={styles.partnerThingsYourChild}>
          Who can be the partner
        </div>
        <div className={styles.partnerKnowWhatMajor}>
          We are bringing expertise from all aspects of the best practises to form the best team out there. Join us today to be a valuable asset to our amazing team.
        </div>
      </div>


      <div className={styles.divParent}>
        <div className={styles.divGroup}>
          <div className={styles.div1}>
              <img className={styles.selIcon} alt="" src="/iconpartner1.png" />
            <p className={styles.b}>
              <span className={styles.txt}>
                School Vendors
              </span>
            </p>
            <div
              className={styles.ofAllWorking}
            >{``}</div>
          </div>
          <div className={styles.div1}>
              <img className={styles.selIcon} alt="" src="/iconpartner2.png" />
            <p className={styles.b}>
              <span className={styles.txt}>
                 Consultants
              </span>
            </p>
          </div>
          <div className={styles.div1}>
              <img className={styles.selIcon} alt="" src="/iconpartner3.png" />
            <p className={styles.b2}>
              Publishers
            </p>
          </div>
          <div className={styles.div1}>
              <img className={styles.selIcon} alt="" src="/iconpartner4.png" />
            <p className={styles.b2}>
              IT Company
            </p>
          </div>
          <div className={styles.div1}>
              <img className={styles.selIcon} alt="" src="/iconpartner5.png" />
            <p className={styles.b2}>
              Entrepreneur
            </p>
          </div>    
          <div className={styles.div1}>
             <img className={styles.selIcon} alt="" src="/iconpartner6.png" />
            <p className={styles.b2}>
               Educationist
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default PartnerContent4;
