import styles from "./SelContent7.module.css";

const SelContent7 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Emotional intelligence for Kids
          </div>
          <div className={styles.getThemReady}>
            Age-appropriate tools and techniques helping kids recognize and manage their emotions, develop empathy, and improve decision-making, communication, and relationship skills for a successful and fulfilling life
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-13.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Play and Learn Empathy</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-14.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Calming Techniques</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-15.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Importance of Empathy</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-16.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Emotional Intelligence</span>
               </div>
            </div>
        </div>

      
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/selimg5.svg"
          />
        </div>

      </div>
    </div>
  );
};

export default SelContent7;
