import styles from "./AboutContent2.module.css";

const AboutContent2 = () => {
  return (
    <div className={styles.vision}>
      <div className={styles.section}>
        <div className={styles.text}>
          <div className={styles.div}>About us</div>
          <i className={styles.everyChildReads}>
            We are mental well-being service provider
          </i>
          <div className={styles.mentalEducationIsContainer}>
            <p className={styles.mentalEducationIs}>
              Genxture is created by experienced senior professionals and Psychologist who left their comfortable jobs to pursue their passion for making a meaningful difference to people’s lives in large scale.
            </p>
          </div>
        </div>
        <img
          className={styles.businessVisionPana1}
          alt=""
          src="/aboutgenxturelogo.svg"
        />
      </div>
    </div>
  );
};

export default AboutContent2;