import Meta from "../components/Meta";
import {
  Link,
} from "react-router-dom";
import Header from "../components/Header";
import HomeContent1 from "../components/HomeContent1";
import HomeContent2 from "../components/HomeContent2";
import HomeContent3 from "../components/HomeContent3";
import HomeContent4 from "../components/HomeContent4";
import HomeContent5 from "../components/HomeContent5";
import HomeContent6 from "../components/HomeContent6";
import HomeContent7 from "../components/HomeContent7";
import ParentsTestimonialSlider from "../components/ParentsTestimonialSlider";
import Footer from "../components/Footer";
import styles from "./Home.module.css";

const Home = () => {

  const images = ['/aboutusperson1.png', '/aboutusperson2.png', '/aboutusperson3.png'];
  const content = ['<img src="/quotes.svg" style="height: 52px; width: 52px; overflow: hidden;"><br><img src="/group-861.svg" style="height: 24px; width: 152px; overflow: hidden;"><div className={styles.text}>This is what we should really teach our kids. Very specific to the current challenges of kids ..i found tools provided are practical and effective.</div><br><div style="font-size:44px; font-family: Inter; font-style: normal; opacity: 10; font-weight: bold;">Father of Aarav</div><div style="font-size:20px; font-family: Inter; font-style: normal; opacity: 10;">Class 6 | Kolkata</div>',
                   '<img src="/quotes.svg" style="height: 52px; width: 52px; overflow: hidden;"><br><img src="/group-861.svg" style="height: 24px; width: 152px; overflow: hidden;"><div className={styles.text}>This is what we should really teach our kids. Very specific to the current challenges of kids .Thanks to the team to come up with powerful session.i found tools provided are practical and effective.</div><br><div style="font-size:44px; font-family: Inter; font-style: normal; opacity: 10; font-weight: bold;">Father of Jatin</div><div style="font-size:20px; font-family: Inter; font-style: normal; opacity: 10;">Class 9 | Mumbai</div>',
                   '<img src="/quotes.svg" style="height: 52px; width: 52px; overflow: hidden;"><br><img src="/group-861.svg" style="height: 24px; width: 152px; overflow: hidden;"><div className={styles.text}>This is what we should really teach our kids. Very specific to the current challenges of kids .Thanks to the team to come up with powerful session.</div><br><div style="font-size:44px; font-family: Inter; font-style: normal; opacity: 10; font-weight: bold;">Father of Nikita</div><div style="font-size:20px; font-family: Inter; font-style: normal; opacity: 10;">Class 7 | Delhi</div>'];
  return (

    <div className={styles.home}>
      <div className={styles.hero}>
        <img className={styles.maskGroupIcon} alt="" src="/mask-group@2x.webp" />
        <img className={styles.frameIcon} alt="" src="/frame.svg" />
        <Header />
        <HomeContent1 />
      </div>
      <HomeContent2 />
      <HomeContent3 />
      <HomeContent4 />
      <HomeContent5 />
      <HomeContent6 />
      <HomeContent7 />
      <div className={styles.divSliderHead}>
        <div className={styles.whatParentsSay}>What Parents say about Us</div>
      </div>
      <ParentsTestimonialSlider images={images} content={content} />
      <div className={styles.divWrapper}>
        <div className={styles.div}>
          <div className={styles.withGenxtureYourContainer}>
            <p className={styles.withGenxture}>{`With Genxture, `}</p>
            <p className={styles.withGenxture}>your Child is the NexGen.</p>
          </div>
          <div className={styles.trustedBy1}>
            Trusted by 1 Million+ students
          </div>
          <div style={{display: 'none' /*App*/}} className={styles.playStoresvgParent}>
            <Link style={{textDecoration: 'none', color: 'white'}} to="https://play.google.com/store/apps/details?id=com.genexture"><img
              className={styles.playStoresvgIcon}
              alt=""
              src="/play-storesvg1.svg"
            /></Link>
            <img
              className={styles.playStoresvgIcon}
              alt=""
              src="/app-storesvg1.svg"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
