import HeaderSection from "./HeaderSection";
import styles from "./Header.module.css";
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";

import {
  Link,
} from "react-router-dom";

const Header = () => {

  const navigate = useNavigate();
  const [display, setDisplay] = useState(false);

  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };

  return (
    <div className={styles.header}>
      <div className={styles.div}>
        <div className={styles.ulmenuPrimaryMenu1}>
          <Link style={{ textDecoration: 'none', color: 'white' }} to="/"><HeaderSection
            studentParentId="Home"
            studentParentCode=""
          /></Link>

          <li><HeaderSection
            studentParentId="Products »"
            studentParentCode=""
          />
            <ul>
              <li> <Link style={{ textDecoration: 'none', color: 'white' }} to="/Sel"><HeaderSection
                studentParentId="Social-emotional learning (SEL)"
                studentParentCode=""
              /></Link></li>
              <li><Link style={{ textDecoration: 'none', color: 'white' }} to="/Career"><HeaderSection
                studentParentId="Career discovery program"
                studentParentCode=""
              /></Link></li>

            </ul>
          </li>

          <Link style={{ textDecoration: 'none', color: 'white' }} to="/Parents"><HeaderSection
            studentParentId="Parents"
            studentParentCode=""
          />
          </Link>
          <Link style={{ textDecoration: 'none', color: 'white' }} to="/School"><HeaderSection
            studentParentId="Schools"
            studentParentCode=""
          /></Link>
          <Link style={{ textDecoration: 'none', color: 'white' }} to="/Ttp"><HeaderSection
            studentParentId="Teacher"
            studentParentCode=""
          /></Link>

          <Link style={{ textDecoration: 'none', color: 'white' }} to="/AboutUs"><HeaderSection
            studentParentId="About Us"
            studentParentCode=""
          /></Link>
        </div>
        {display && <div className={styles.ulmenuPrimaryMenu}>
          <img onClick={() => setDisplay(!display)} style={{ height: '35px', margin: '10px', marginBottom: 0, marginLeft: '10px' }} alt="" src="/close_c.png" />
          <Link style={{ textDecoration: 'none', color: 'white' }} to="/"><HeaderSection
            studentParentId="Home"
            studentParentCode=""
          /></Link>

          <li><HeaderSection
            studentParentId="Products »"
            studentParentCode=""
          />
            <ul>
              <li> <Link style={{ textDecoration: 'none', color: 'white' }} to="/Sel"><HeaderSection
                studentParentId="Social-emotional learning (SEL)"
                studentParentCode=""
              /></Link></li>
              <li><Link style={{ textDecoration: 'none', color: 'white' }} to="/Career"><HeaderSection
                studentParentId="Career discovery program"
                studentParentCode=""
              /></Link></li>

            </ul>
          </li>
          <Link style={{ textDecoration: 'none', color: 'white' }} to="/Parents"><HeaderSection
            studentParentId="Parents"
            studentParentCode=""
          />
          </Link>
          <Link style={{ textDecoration: 'none', color: 'white' }} to="/School"><HeaderSection
            studentParentId="Schools"
            studentParentCode=""
          /></Link>
          <Link style={{ textDecoration: 'none', color: 'white' }} to="/Ttp"><HeaderSection
            studentParentId="Teacher"
            studentParentCode=""
          /></Link>
          <Link style={{ textDecoration: 'none', color: 'white' }} to="/AboutUs"><HeaderSection
            studentParentId="About Us"
            studentParentCode=""
          /></Link>
          <a style={{ textDecoration: 'none', color: 'white' }} href="https://www.careertest.genxture.com/">
            <div className={styles.a1}>
              <div className={styles.getTheApp}>Login</div>
            </div>
          </a>
        </div>

        }
        <a className={styles.loginMenu} style={{ textDecoration: 'none', color: 'white' }} href="https://www.careertest.genxture.com/">
          <div className={styles.a1}>
            <div className={styles.getTheApp}>Login</div>
          </div>
        </a>
      </div>
      <Link style={{ textDecoration: 'none', color: 'white' }} to="/"><img onClick={navigateHome} className={styles.frameIcon} alt="" src="/frame1.svg" /></Link>
      <img className={styles.hamMenu} onClick={() => setDisplay(!display)} style={{ right: '1%', position: 'absolute' }} alt="" src="/ham-menu.png" />

    </div>

  );
};

export default Header;
