import styles from "./AboutHeaderLinks.module.css";

const AboutHeaderLinks = ({ studentParentId, studentParentCode }) => {
  return (
    <div className={styles.a}>
      <div className={styles.students}>{studentParentId}</div>
      <img className={styles.aafterIcon} alt="" src={studentParentCode} />
      <div className={styles.text} />
    </div>
  );
};

export default AboutHeaderLinks;
