import styles from "./SelContent9.module.css";

const SelContent9 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Growth mind set
          </div>
          <div className={styles.getThemReady}>
            Growth mindset can lead to success in every aspect of life. The course helps children develop this mindset, including strategies like goal-setting, problem-solving, and self-compassion.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-21.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Fostering Resilience and Grit</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-22.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Mindful Coping Strategies for Kids</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-23.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Developing a Growth Mindset</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-24.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Teaching Kids to Persevere</span>
               </div>
            </div>
        </div>

      
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/selimg7.svg"
          />
        </div>

      </div>
    </div>
  );
};

export default SelContent9;
