import styles from "./CommonContent6.module.css";

const CommonContent6 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon} alt="" src="/union1.svg" />
      <div className={styles.steps}>
        <div className={styles.divider} />
        <div className={styles.step}>
          <div style={{display: 'none' /*App*/}} className={styles.text1}>
          <img 
            className={styles.illustrationIcon1}
            alt=""
            src="/commonqr.svg"
          />
          </div>


        </div>
         <div className={styles.text2}>
          <img
            className={styles.illustrationIcon2}
            alt=""
            src="/commonrectangle1.svg"
          />
         </div>
      </div>
    </div>
  );
};

export default CommonContent6;
