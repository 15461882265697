import styles from "./SchoolContent7.module.css";

const SchoolContent7 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/union8.svg" />
      <img className={styles.unionIcon2} alt="" src="/union05.svg" />
      
      <div className={styles.steps}>

        <div className={styles.divider} />
        <div className={styles.step}>

          <img
            className={styles.illustrationIcon}
            alt=""
            src="/schoolimg6.svg"
          />
        </div>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            School ERP
          </div>
          <div className={styles.getThemReady}>
            Optimize your academic and administrative tasks with our ERP system under your own brand. Automate repetitive processes and use a centralized information system to enhance coordination, expedite task completion, and leverage data-driven operations for enhanced accuracy and efficiency.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Management Easy and Seamless Communication </span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Improved Fee Collection and Efficient Workflow </span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Proper Enquiry Management </span>
               </div>
            </div>
               <div style={{width: '190px'}} className={styles.a}>
                 <div style={{fontSize: '18px',textAlign:'center'}} className={styles.startLearning}>Learn More</div>
               </div> 

        </div>

      </div>
    </div>
  );
};

export default SchoolContent7;
