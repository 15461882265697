import styles from "./ContainerPrivacyPolicy.module.css";

const ContainerPrivacyPolicy = () => {
  return (
    <div className={styles.ContainerPrivacyPolicyBox}>
    <text>

    <div className={styles.t1}>
      Privacy Policy
    </div>
By using our Web site, you agree to comply with our policies. Our Privacy Policy is designed to assist you in understanding how we collect and use the personal information you provide to us and to assist you in making informed decisions when using our site and our products and services.
    
    <div className={styles.t2}>
WHAT INFORMATION DO WE COLLECT?
</div>

When you visit our Web site you may provide us with two types of information: personal information you knowingly choose to disclose that is collected on an individual basis and Web site usage information collected on an aggregate basis as you and others browse our Web site.

    <div className={styles.t2}>
PERSONAL INFORMATION YOU CHOOSE TO PROVIDE
</div>

    <div className={styles.t2}>
Registration Information:
</div>
 

You will provide us information about yourself that will enable us to verify whether you are entitled to access and use certain information, materials and services available from the Web site <span className={styles.company}>Genxture</span>  This information may also be used to enable us to enhance your Site visit, to assist you with customer service or technical support issues, or to follow up with you after your visit, or to otherwise support your relationship with <span className={styles.company}>Genxture</span> It is completely optional for you to participate. However, failure to participate may mean that you cannot access and use certain information, materials and services. For example, we request information from you when you:

Log on to certain areas of the Web site <span className={styles.company}>Genxture</span> where you may be prompted to provide us with your Login Name and Password as a condition to gain access to certain information, materials or services.

Register or sign-up to use a service.

Place an order.

Provide feedback in an online survey.

In each of the instances above, we typically ask for your name, e-mail address, phone number, address, login Name and Password, as well as, other similar personal information that is needed to register or subscribe you to services or offers. On occasion, we may ask for additional information to enable us to provide you with access to and use of certain information, materials and services. In the case of newsletters or mailing lists, you will be able to “unsubscribe” to these mailers at any time.

Wherever <span className={styles.company}>Genxture</span> collects personal information we make an effort to include a link to this Privacy Policy on that page

    <div className={styles.t2}>

How we use information

 </div>

a. Personal Information -The personal information you provide to us when using the application, such as your name, postal, e-mail address or telephone number will be kept confidential and used to support your relationship with <span className={styles.company}>Genxture</span> to notify you of special offers, updated information and new products and services from <span className={styles.company}>Genxture</span>, or offers from third parties that we think may be of interest to you. Agents or contractors of <span className={styles.company}>Genxture</span>, who are given access to your personal information will be required to keep the information confidential and not use it for any other purpose other than to carry out the services they are performing for <span className={styles.company}>Genxture</span>.

b. Camera - <span className={styles.company}>Genxture</span> requires Camera access to enable app main features like, photos taken using camera will be used only for the mentioned use cases. This feature is used to upload user profile picture. 

c. Storage permission - <span className={styles.company}>Genxture</span> requires storage permission to enable app main features like, photos taken from gallery will be used only for the mentioned use cases. This feature is used to upload user profile picture.

 

 
    <div className={styles.t2}>

Sharing information with Third Parties
</div>
<span className={styles.company}>Genxture</span>  may enhance or merge your information collected at its Web site with data from third parties for purposes of marketing products or services to you. Concerning network advertising companies (companies that manage and provide advertising for numerous unrelated companies), to the extent that <span className={styles.company}>Genxture</span>  utilizes such advertising companies to provide advertisements on the Web site, may provide them with your Sign in Name and any demographic information about you that we collect. Those advertising companies may combine that data with non-personally identifiable data collected by the advertising company from your computer, solely to deliver on our Web site, advertisements that are targeted to you.

Circumstances may arise where we are required to disclose your personal information to third parties for purposes other than to support your customer relationships with <span className={styles.company}>Genxture</span>   , such as in connection with a corporate divestiture or dissolution where we sell all or a portion of our business or assets (including our associated customer lists containing your personal information), or if the disclosure is required by law or is pertinent to judicial or governmental investigations or proceedings.

We will send you information about our various products and services, or other products and services we feel may be of interest to you. Only <span className={styles.company}>Genxture</span>  (or agents or contractors working on behalf of <span className={styles.company}>Genxture</span>  and under confidentiality agreements) will send you these direct mailings.

    <div className={styles.t2}>

Cookies
</div>

A cookie is a very small text document, which often includes an anonymous unique identifier. When you visit a Web site, that site’s computer asks your computer for permission to store this file in a part of your hard drive specifically designated for cookies. Each Web site can send its cookie to your browser if your browser’s preferences allow it, but (to protect your privacy) your browser only permits a Web site to access the cookies it has already sent to you, not the cookies sent to you by other sites.

The use of cookies makes web surfing easier by performing certain functions such as saving your Passwords, and your personal preferences regarding your use of the particular Web site and to make sure you don’t see the same ad repeatedly. Many consider the use of cookies to be an industry standard. If you choose to have your browser refuse cookies, it is possible that some areas of our Site will not function properly. You may note additionally that if you visit the <span className={styles.company}>Genxture</span>   Web site where you are prompted to log in or that is customizable, you may be required to accept cookies. Advertisers and partners may also use cookies. We do not control the use of these cookies and disclaim responsibility for information collected through them.

How Do We Secure Information Transmissions?

Email is not recognized as a secure medium of communication. For this reason, we request that you do not send private information to us by email. This Site does not, however, provide facilities for the secure transmission of information across the Internet. You should be aware that there are inherent risks associated with the transmissions of Personal Information via the Internet.

If you do not wish to use the Internet to transmit personal information you can mail or phone us, Details are provided under ‘How to Contact Us.

    <div className={styles.t2}>

How Can You Access and Correct Your Information?
</div>

Email is not recognized as a secure medium of communication. You may request access to all your personally identifiable information that we collect online and maintain in our database by calling <span className={styles.company}>Genxture</span> .

    <div className={styles.t2}>

Certain Disclosures
</div>

We may disclose your personal information if required to do so by law or subpoena or if we believe that such action is necessary to (a) conform to the law or comply with the legal process served on us or affiliated parties; (b) protect and defend our rights and property, our site, the users of our site, and/or our affiliated parties; (c) act under circumstances to protect the safety of users of our site, us, or third parties.

What About Other Web sites Linked to Our Web site?

We are not responsible for the practices employed by Web sites linked to or from our Web site nor the information or content contained therein. Often links to other Web sites are provided solely as pointers to information on topics that may be useful to the users of our Web site.

Please remember that when you use a link to go from our Web site to another Web site, our Privacy Policy is no longer in effect. Your browsing and interaction on any other Web site, including Web sites which have a link to our Web site, is subject to that Web site’s own rules and policies. Please read over those rules and policies before proceeding.

    <div className={styles.t2}>
Children’s Privacy
</div>

We do not knowingly collect personal information from children below the age of 15. If we learn that we have personal information on a child below the age of 15, we will delete that information from our systems. Even for the personal information of children of the age of 15 years and above, the personal information is collected only for the purpose of their al requirements and service needs as provided by this Web site.

Spamming

Sending unsolicited mail messages, including, without limitation, commercial advertising and informational announcements, is expressly prohibited. A user shall not use another site’s mail server to relay mail without the express permission of the site.

It is contrary to <span className={styles.company}>Genxture</span>  policy for customers to use our servers to effect or participate in any of the following activities:

To post to any Usenet or other newsgroup, forum, e-mail mailing list or other similar group or list articles which are off-topic according to the agreement or other owner-published FAQ or description of the group or list;

To send unsolicited mass e-mailings, if such unsolicited e-mailings provoke complaints from the recipients;

To engage in any of the foregoing activities using the service of another provider, but channeling such activities through <span className={styles.company}>Genxture</span>  provided server, or using <span className={styles.company}>Genxture</span>  provided server as a mail drop for responses;

To falsify user information provided to <span className={styles.company}>Genxture</span>   or to other users of the service in connection with the use of <span className={styles.company}>Genxture</span>  service.

<div className={styles.t2}>
Consequences of Violation:
</div>

When <span className={styles.company}>Genxture</span>  becomes aware of an alleged violation of its Acceptable Use Policy, <span className={styles.company}>Genxture</span>  will initiate an investigation. During the investigation, <span className={styles.company}>Genxture</span>  may restrict the Customer‘s access to prevent further possible unauthorized activity. Depending on the severity of the violation, <span className={styles.company}>Genxture</span>  may, at its sole discretion, restrict, suspend, or terminate the Customer’s account and pursue other civil remedies. If such a violation is a criminal offence, <span className={styles.company}>Genxture</span>  will notify the appropriate law enforcement department of such a violation.
    </text>
    </div>
  );
};

export default ContainerPrivacyPolicy;