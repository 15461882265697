import styles from "./SelContent10.module.css";

const SelContent10 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/union8.svg" />
      <div className={styles.ourProcess}>Program Flow</div>
      <div className={styles.steps}>

          <img
            className={styles.imgdata1}
            alt=""
            src="/seldata1.png"
          />
          <img
            className={styles.imgdata2}
            alt=""
            src="/seldata02.svg"
          />
      </div>
    </div>
  );
};

export default SelContent10;
