import styles from "./CommonContent4.module.css";

const CommonContent4 = () => {
  return (
    <div className={styles.features}>
      <div className={styles.genextureProvesIts}>
        Career Discovery Program
      </div>

      <div className={styles.frameParent}>
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/parents-graduation.svg"
          />
          <img
            className={styles.materialSymbolsplayCircleOIcon}
            alt=""
            src="/materialsymbolsplaycircleoutlinerounded.svg"
          />
        </div>
        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            If your child is Confused about career choices?you child is not alone . Around 95% of students are confused about choosing the right career. 
          </div>
          <div className={styles.getThemReady}>
            Avail Career Counselling from India's best Career Experts .We assess students' skills and potential through 
            <text>
              <ul>
               <li>Personalized career assessments, </li>
               <li>Helping them identify career goals and </li>
               <li>Address skill gaps.</li>               
              </ul>
            </text>
          </div>
          <div className={styles.a3}>
            <a className={styles.knowMore} href="/career" style={{textDecoration:'none'}}>Know More</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonContent4;
