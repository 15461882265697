import AboutHeaderLinks from "./AboutHeaderLinks";
import styles from "./AboutContent1.module.css";

const AboutContent1 = () => {
  return (
    <div className={styles.hero}>
      <img className={styles.maskGroupIcon} alt="" src="/mask-group@2x.webp" />
      <img className={styles.frameIcon} alt="" src="/frame.svg" />
      <div className={styles.header}>
        <div className={styles.div}>
          <div className={styles.ulmenuPrimaryMenu}>
            <AboutHeaderLinks
              studentParentId="Students"
              studentParentCode="/aafter.svg"
            />
            <AboutHeaderLinks
              studentParentId="Parents"
              studentParentCode="/aafter1.svg"
            />
            <AboutHeaderLinks
              studentParentId="Schools"
              studentParentCode="/aafter2.svg"
            />
            <AboutHeaderLinks
              studentParentId="Teacher"
              studentParentCode="/aafter3.svg"
            />
            <div className={styles.a}>
              <div className={styles.students}>More</div>
              <img className={styles.aafterIcon} alt="" src="/aafter4.svg" />
              <div className={styles.text} />
            </div>
          </div>
          <div className={styles.a1}>
            <div className={styles.getTheApp}>Get The App</div>
          </div>
        </div>
        <img className={styles.frameIcon1} alt="" src="/frame1.svg" />
      </div>
      <div className={styles.frameParent}>
        <div className={styles.wereChangingTheWholeGameParent}>
          <div className={styles.wereChangingThe}>
            Our Passion Drives Our Purpose: Making a Lasting Impact on Society
          </div>
          <div className={styles.weFoundThe}>
            Empowering individuals, strengthening communities throgh mental health wellness program
          </div>
        </div>
        <img className={styles.teamPana1Icon} alt="" src="/aboutteampana-1.svg" />
      </div>
      <img className={styles.heroChild} alt="" src="/aboutvector-2.svg" />
      <img className={styles.heroItem} alt="" src="/aboutvector-3.svg" />
    </div>
  );
};

export default AboutContent1;
