import styles from "./LearningAppContent3.module.css";

const LearningAppContent3 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Genxture Test Preparation Programmer
          </div>
          <div className={styles.getThemReady}>
            Prepare for your exams with the right guidance. We will make sure you have all your doubts and concerns cleared.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/learningappicon12.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Question sets with explanatory solutions</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/learningappicon13.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Latest study material on subject matter with tutor support.</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/learningappicon14.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Support on examination ethics and successful preparation methods</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/learningappicon15.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Live support from subject matter experts and counselors</span>
               </div>
            </div>


        </div>

      
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/LearningAppPhone2.svg"
          />
        </div>

      </div>
    </div>
  );
};

export default LearningAppContent3;
