import React, { useState, useEffect } from 'react';
import styles from "./ParentsTestimonialSlider.module.css";

const ParentsTestimonialSlider = ({ content, images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (content && images && content.length && images.length) {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
      }, 3000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [content, images]);

  const goToPrevious = () => {
    if (content && content.length) {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + content.length) % content.length);
    }
  };

  const goToNext = () => {
    if (content && content.length) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
    }
  };

  if (!content || !images || !content.length || !images.length) {
    return null; // or display a loading state, error message, or fallback content
  }

  return (
    <div className={styles.autoSlider}>

      <div className={styles.sliderContent}>
            <button style={{marginRight: '-25px', zIndex: '1', border: 'none', cursor: 'pointer'}} className={styles.prevBtn} onClick={goToPrevious}><img className={styles.chevronIcon} alt="" src="/chevron.svg" /></button>
              <img className={styles.mainImage} src={images[currentIndex]} alt="Slider Image" />
            <button style={{marginLeft: '-25px', zIndex: '1', border: 'none', cursor: 'pointer'}} className={styles.prevBtn} onClick={goToNext}><img className={styles.chevronIcon} alt="" src="/chevron1.svg" /></button>
      </div>
              <div className={styles.sliderContentPara} dangerouslySetInnerHTML={{ __html: content[currentIndex] }}></div>

    </div>
  );
};

export default ParentsTestimonialSlider;
