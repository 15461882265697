import styles from "./LearningAppContent6.module.css";

const LearningAppContent6 = () => {
  return (
    <div className={styles.numbers}>

      <div className={styles.steps}>
        <div className={styles.divider} />
        <div className={styles.step}>
          <div className={styles.text1}>
          <img
            className={styles.illustrationIcon1}
            alt=""
            src="/learningappcontent6.svg"
          />
          </div>

          <div className={styles.text2}>
          <img
            className={styles.illustrationIcon2}
            alt=""
            src="/LearningAppImgApp.png"
          />
          </div>
        </div>


      </div>
    </div>
  );
};

export default LearningAppContent6;
