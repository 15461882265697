import styles from "./LmsContent6.module.css";

const LmsContent6 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/union5.svg" />
      <img className={styles.unionIcon2} alt="" src="/union04.svg" />

      <div className={styles.div}>
        <div className={styles.whyGenxture}>Features</div>
        <div className={styles.genxtureFocusesOn}>

        </div>
    <div className={styles.threeboxes}>
       <div className={styles.threeboxesContainer}>
         <div className={styles.threeboxesContainerBox}>
            <img className={styles.iconLms} alt="" src="/lmsicon1.png" />
            <p className={styles.LmsContent6txt}>Live Classes & Recording</p>
         </div>
         <div className={styles.threeboxesContainerBox}>
           <img className={styles.iconLms} alt="" src="/lmsicon2.png" />
           <p className={styles.LmsContent6txt}>Assign Homework</p>
         </div>
         <div className={styles.threeboxesContainerBox}>
            <img className={styles.iconLms} alt="" src="/lmsicon3.png" />
            <p className={styles.LmsContent6txt}>K-12 Learning Resources</p>
         </div>
       </div>
    </div>
    <div className={styles.threeboxes}>
       <div className={styles.threeboxesContainer}>
         <div className={styles.threeboxesContainerBox}>
            <img className={styles.iconLms} alt="" src="/lmsicon4.png" />
            <p className={styles.LmsContent6txt}>Create and Evaluate Tests</p>
         </div>
         <div className={styles.threeboxesContainerBox}>
            <img className={styles.iconLms} alt="" src="/lmsicon5.png" />
            <p className={styles.LmsContent6txt}>Private and classroom chat</p>
         </div>
         <div className={styles.threeboxesContainerBox}>
            <img className={styles.iconLms} alt="" src="/lmsicon6.png" />
            <p className={styles.LmsContent6txt}>Classroom Analytics</p>
         </div>
       </div>
    </div>
    <div className={styles.threeboxes}>
       <div className={styles.threeboxesContainer}>
         <div className={styles.threeboxesContainerBox}>
            <img className={styles.iconLms} alt="" src="/lmsicon8.png" />
            <p className={styles.LmsContent6txt}>Notice board</p>
         </div>
         <div className={styles.threeboxesContainerBox}>
            <img className={styles.iconLms} alt="" src="/lmsicon9.png" />
            <p className={styles.LmsContent6txt}>Digital whiteboard</p>
         </div>
         <div className={styles.threeboxesContainerBox}>
           <img className={styles.iconLms} alt="" src="/lmsicon10.png" />
           <p className={styles.LmsContent6txt}>Class schedule</p>
         </div>
       </div>
    </div>

      </div>



    </div>
  );
};

export default LmsContent6;
