import styles from "./TtpContent1.module.css";
import RegistrationPopup from "./RegistrationPopup";

const TtpContent1 = () => {
  return (
    <div className={styles.divParent}>
      <div className={styles.div}>

        <b className={styles.noChildWillContainer}>
          <span>{`A visionary educator for a changing world - `}</span><span className={styles.emotionalIntelligence}>Teacher of Tomorrow&nbsp;</span>
        </b>

        <div className={styles.genxtureBringsTheContainer}>

          <span>{`A visionary teacher inspires and shapes students into future leaders and innovators by promoting safe space for menatl health , critical thinking, creativity, and adapting to change. They prepare students for the future by imparting essential skills and knowledge`}</span>

        </div>

        <div className style={{width:'100%'}}>
          <div className><RegistrationPopup text="Start Learning" style1={styles.a} style2={styles.startLearning}/></div>
        </div>


      </div>
      <img className={styles.heroIcon} alt="" src="/TtpImage1.png" />
    </div>
  );
};

export default TtpContent1;
