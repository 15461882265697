import styles from "./CommonContent2.module.css";

const CommonContent2 = () => {
  return (
    <div className={styles.divParent}>
      <div className={styles.div}>
        <div className={styles.thingsYourChild}>
          As parents, we want the best for our children and provide them with the best education and environment possible. 
        </div>
        <div className={styles.knowWhatMajor}>
        <text>
        <p>However, do we equip them with the right mindset to deal with tough emotions?</p>
        <p>We teach our kids good values, but do we also teach them how to handle tough emotions like anger, frustration, panic attacks and other challenging emotions ? 
        It's important to consider how we're equipping them to take care of their mental health and face any situation in life.</p>
        <p>Instant gratification from technology (Mobile, social media) adversely affect their mental health, so it's essential to provide them with tools to stay calm, happy, and focused. </p>
        <p>Genxture offers age-appropriate programs for children aged 6 to 15 years old to help them reach their full potential. Their structured programs provide social-emotional learning, which complements conventional schooling and focuses on overall development. 
        We use a enquiry based learning  to make the session highly effective. </p>
        <p>Working closely with parents and schools, We help kids acquire high emotional intelligence, manage stress and anxiety, and improve their attention span and focus. 
        Children who have participated in their programs have shown better social, emotional, physical, mental, and academic improvement."</p>
        </text>
        </div>
      </div>
      <div className={styles.thingsYourChild}>
        Our Courses
      </div>
      <div className={styles.knowWhatMajor}>
        Our courses utilize a range of tools including animations, stories, worksheets, guided visualization,
        and brain wave music to teach children effective strategies for managing negative emotions such as anger,
        stress, anxiety, fear, panic attacks, and insecurity.Our courses teach children how to manage challenging situations while also promoting the development of positive emotions such as empathy,
        love, compassion, gratitude, and kindness. Enrolling your children in our courses can help them lead a happier and more fulfilling life
      </div>
      <div className={styles.section}>

      </div>
    </div>
  );
};

export default CommonContent2;
