import styles from "./LearningAppContent2.module.css";

const LearningAppContent2 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/LearningAppPhone1.svg"
          />
        </div>
        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Bring Classroom to your home/ Your classroom in your palms
          </div>
          <div className={styles.getThemReady}>
            Let your smartphone/tab be your. The Genxture app enables you to attend your classes and finish your assignments whenever and wharever you want.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/learningappicon9.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Timely updates of assignments and study plans</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/learningappicon10.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Feedback and suggestions regarding performance and improvement</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/learningappicon11.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Connect with fellow students to talk and solve problems collectively</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/learningappicon12.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Seamless support from experts mentors </span>
               </div>
            </div>

        </div>
      </div>
    </div>
  );
};

export default LearningAppContent2;
