import styles from "./CareerContent3.module.css";

const CareerContent3 = () => {
  return (
    <div className={styles.numbers}>
      <div className={styles.ourCulture}>
        <div className={styles.theGenxtureEndeavor}>
          Step-Wise Career Discovery
        </div>

        <div className={styles.CarrerPara}>
         Our mission is to guide individuals towards career awareness, one step at a time.
        </div>

        <div className={styles.divParent}>
          <div className={styles.div}>
            <img
              className={styles.divChild}
              alt=""
              src="/careericon1.png"
            />
            <div className={styles.curiosity}>Assessment</div>
            <div className={styles.buildTrustingRelationships}>
              Psychometric Assessment to understand skill gap and recommend top 3 career recommendation.
            </div>
          </div>
          <div className={styles.div1}>
            <img
              className={styles.divChild}
              alt=""
              src="/careericon2.png"
            />
            <div className={styles.curiosity}>Advice</div>
            <div className={styles.buildTrustingRelationships}>
              In-person Counseling to understand the results of your report and identify your key career goals..
            </div>
          </div>
          <div className={styles.div2}>
            <img
              className={styles.divChild}
              alt=""
              src="/careericon3.png"
            />
            <div className={styles.curiosity}>Acquisition </div>
            <div className={styles.buildTrustingRelationships2}>
              Explore options on planning and preparation to achieve your career goal.
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default CareerContent3;
