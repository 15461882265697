import styles from "./LmsContent4.module.css";

const LmsContent4 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/Lmsmodernart3.png"
          />
        </div>
        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Seamless Communication
          </div>
          <div className={styles.getThemReady}>
            <ul>
             <li>Ensure smooth classroom management with private & classroom chat</li>
             <li>Real-time notifications & updates</li>
             <li>Share important updates through the noticeboard</li>
            </ul>  
          </div>

        </div>
      </div>
    </div>
  );
};

export default LmsContent4;
