import styles from "./AboutTeamContainer.module.css";

const AboutTeamContainer = ({ image, marvinMcKinney, productManager }) => {
  return (
    <div className={styles.teamCard}>
      <img className={styles.imageIcon} alt="" src={image} />
      <div className={styles.info}>
        <div className={styles.marvinMckinney}>{marvinMcKinney}</div>
        <div className={styles.productManager}>{productManager}</div>
      </div>
    </div>
  );
};

export default AboutTeamContainer;
