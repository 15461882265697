import styles from "./LmsContent7.module.css";
import React,{useState} from 'react'
import $ from 'jquery';

import svg1 from "../svg1.svg";
import svg from "../svg.svg";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const LmsContent7 = () => {

  const [name1, setName1] = useState(svg1);
  const [name2, setName2] = useState(svg1);
  const [name3, setName3] = useState(svg1);
  const [name4, setName4] = useState(svg1);
  const [name5, setName5] = useState(svg1);


  const changeName1 = () => {
    let value1 = name1;

    if (value1 === svg1) {
      setName1(svg);
    } else {
      setName1(svg1);
    }
  };


  const changeName2 = () => {
    let value2 = name2;

    if (value2 === svg1) {
      setName2(svg);
    } else {
      setName2(svg1);
    }
  };

  const changeName3 = () => {
    let value3 = name3;

    if (value3 === svg1) {
      setName3(svg);
    } else {
      setName3(svg1);
    }
  };


  const changeName4 = () => {
    let value4 = name4;

    if (value4 === svg1) {
      setName4(svg);
    } else {
      setName4(svg1);
    }
  };

  const changeName5 = () => {
    let value5 = name5;

    if (value5 === svg1) {
      setName5(svg);
    } else {
      setName5(svg1);
    }
  };  

    const[show1,setShow1]=useState(false);
    const[show2,setShow2]=useState(false);
    const[show3,setShow3]=useState(false);
    const[show4,setShow4]=useState(false);
    const[show5,setShow5]=useState(false);  
   
    const Accordion = styled((props: AccordionProps) => (
      <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
      border: `none`,
      
      '& .css-1betqn-MuiAccordionSummary-content .css-ahj2mt-MuiTypography-root':{
        margin:`10px 0`,
        marginLeft:`16px`,
        fontSize:`20px`,
        fontWeight: `600`,
        fontFamily: `Inter`,
        padding: `25px`,
      },
      '& .MuiAccordionSummary-content .MuiTypography-root':{
        margin:`10px 0`,
        marginLeft:`16px`,
        fontSize:`20px`,
        fontWeight: `600`,
        fontFamily: `Inter`,
       
      },
      '& .css-ahj2mt-MuiTypography-root':{
        fontFamily: `Inter`,
        fontSize:`16px`,      
      },
      '& .MuiAccordionDetails-root':{
        fontFamily: `Inter`,
        fontSize:`16px`,
        lineHeight: `30`,
        paddingLeft: `60px`,
      },
      '.Mui-expanded':{
        background:`#fff`,
        borderRadius:`inherit`,
        borderBottomLeftRadius:`unset`,
        borderBottomRightRadius:`unset`,
      },
      '.MuiAccordionDetails-root':{
        background:`#fff`,
        borderRadius:`inherit`,
        borderTopLeftRadius:`unset`,
        borderTopRightRadius:`unset`,
    
      },
      '.css-i4bv87-MuiSvgIcon-root':{
        fontSize:`2rem`,
        color:`black`,
      },

      '&:before': {
        backgroundColor: 'transparent',
      },
      background:`#FAFAFA`,
      borderRadius: `8px`,
      fontSize: `20px`,
      width:`inherit`,
      boxShadow:`0px 0px 0px -3px rgba(0,2,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)`,
    }));
    
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
      /*padding: theme.spacing(2),
      borderTop: '1px solid rgba(0, 0, 0, 
        x solid rgba(0, 0, 0, .125)',*/
    }));  
  return (
    <div className={styles.divParent}>
      <div className={styles.div}>
        <div className={styles.whatParentsSay}>Frequently Asked Questions</div>
      </div>
      <div className={styles.section}>

        <div className={styles.section1}>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >

          <Typography>What is a learning management system (LMS)?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          A school LMS is software that helps manage the school's learning process and makes classroom management easier. It helps with documentation, tracking, reporting, automation, and delivering the curriculum in an engaging way.   
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >

          <Typography>What can effect a child's career choices?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            An LMS is a software that helps schools manage their learning process and classroom management. It benefits primary schools by making teachers more efficient and improving learning outcomes for students. LMS portals allow principals to track syllabus completion and monitor individual student progress with analytics. Genxture's LMS offers digital content and tools for learning outside school hours. Parents can track their children's learning journey with a school LMS platform.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >

          <Typography>What factors to consider before implementing a learning management system in schools?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           Before implementing a learning management system in schools, administrators must consider their specific needs and budget. They should evaluate different companies offering LMS and ensure that the system's features align with the school's requirements. Implementing an LMS can enhance the quality of teaching and learning in the school. LMS portals can offer new opportunities to modernize the education system.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >

          <Typography>How does LMS improve the quality of teaching and learning?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography> 
           A school learning management system is a tool that simplifies academic processes and improves the learning experience for students. LMS portals have advanced tools that enable teachers to manage learning requirements and collaborate with students easily. Administrators can use the system to help teachers share resources and connect with students in real-time.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          
          
          <Typography>What are the features of an LMS?.</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Genxture's LMS is a software that has various tools to improve the learning-teaching process. It includes a lesson planner, digital content, assessments, homework, and study material sharing capabilities. The LMS platform also has an inbuilt live class feature with interactive tools like live polls, whiteboard, chat, and more. Regenerate response 
          </Typography>
        </AccordionDetails>
      </Accordion>     

         </div>

      </div>

    </div>
  );
};

export default LmsContent7;
