import styles from "./SelContent4.module.css";
import RegistrationPopup from "./RegistrationPopup";

const SelContent4 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon} alt="" src="/union8.svg" />
      <div className={styles.ourProcess}>K12 Learning App</div>
      <div className={styles.steps}>

        <div className={styles.divider} />
        <div className={styles.step}>

          <img
            className={styles.illustrationIcon}
            alt=""
            src="/selimg-1.png"
          />
        </div>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Focus and concentration
          </div>
          <div className={styles.getThemReady}>
            Poor concentration in kids is a common problem in today's world of over-stimulation. We work on improving confidence, comprehension and retention for long-term academic and social success.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-1.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Faster learning and task completion</span>
                
               </div>

               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-2.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Reduced carelessness with tasks</span>
               </div>
               
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-3.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Increased confidence and independence in learning</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-4.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Improved comprehension</span>
               </div>
            </div>

        <div className style={{width:'100%'}}>
          <div className><RegistrationPopup text="Start Learning" style1={styles.a} style2={styles.startLearning}/></div>
        </div>

        </div>

      </div>
    </div>
  );
};

export default SelContent4;
