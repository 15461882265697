import Meta from "../components/Meta";
import Container from "../components/ContainerPrivacyPolicy";
import styles from "./PrivacyPolicy.module.css";


const PrivacyPolicy = () => {
  return (
    <div className={styles.PrivacyPolicyPage}>
      <Container />
    </div>
  );
};

export default PrivacyPolicy;