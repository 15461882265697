import styles from "./TtpContent2.module.css";

const TtpContent2 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>
        <div className={styles.screenChangeMeParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Why a Teacher Transformation program?
          </div>
          <div className={styles.getThemReady}>
            Education is changing rapidly. Traditional teacher needs to upgrade on skills and methods of content delivery.
          </div>

        </div>

        </div>
        <div className={styles.div}>

          <div className={styles.getThemReady}>

  <div className={styles.threeboxesContainerMain}>
    <div className={styles.threeboxes}>
       <div className={styles.threeboxesContainer}>
         <div className={styles.threeboxesContainerBox}>
           <img
            className={styles.ttponetwothree}
            alt=""
            src="/ttpone.png"
          />
         </div>
         <div className={styles.threeboxesContainerBox}>
          <img
            className={styles.ttponetwothree}
            alt=""
            src="/ttptwo1.png"
          />
         </div>
         <div className={styles.threeboxesContainerBox}>
            <img
            className={styles.ttponetwothree}
            alt=""
            src="/ttpthree.png"
          />
         </div>
       </div>
    </div> 

    <div className={styles.threeboxes}>
       <div className={styles.threeboxesContainer}>
         <div className={styles.threeboxesContainerBox}>
            <p><b>Skill Based</b></p>
            Acquire practical skills that give you an edge over theoretical knowledge.
         </div>
         <div className={styles.threeboxesContainerBox}>
            <p><b>Technology-Driven</b></p>
            Using technology to improve student engagement and interaction.
         </div>
         <div className={styles.threeboxesContainerBox}>
            <p><b>No Boundaries</b></p>
            Empower yourself to become a global educator with no limitations.
         </div>
       </div>
    </div> 
 </div>

                  <img className={styles.threeboxesContainerMainImg}
                   alt=""
                   src="/ttpimage3.svg"
                  />

          </div>

        </div>
      </div>
    </div>
  );
};

export default TtpContent2;
