import AboutTeamContainer from "./AboutTeamContainer";
import styles from "./AboutContent5.module.css";
import { useState, useEffect } from "react";


const AboutContent5 = () => {
  return (
    <div className={styles.team}>
      <div className={styles.div}>
        <div className={styles.meetOurBest}>
          Meet our best educators and mind magicians
        </div>
        <div className={styles.bestMindsDemand}>
          Best Minds demand best mentors. Here's the introductions of our best
          ones. They love kids and kids love them.
        </div>
      </div>
      <div className={styles.line}>
        <AboutTeamContainer
          image="/teamimage-1.png"
          marvinMcKinney="Swaraj Dhar"
          productManager="Technology & Strategy"
        />
        <AboutTeamContainer
          image="/teamimage-2.jpeg"
          marvinMcKinney="Shakti Shah"
          productManager="Finance & Sales"
        />
        <AboutTeamContainer
          image="/teamimage-3.jpeg"
          marvinMcKinney="Bhavani Harikrishan"
          productManager="Product Management"
        />
        <AboutTeamContainer
          image="/sunita.png"
          marvinMcKinney="Sunita Bothra"
          productManager="Product Management"
        />
        <AboutTeamContainer
          image="/teamimage-4.jpeg"
          marvinMcKinney="Deepak Verma"
          productManager=" Operations"
        />
      </div>
      <div className={styles.slider}>
        <div className={styles.shape} />
        <img className={styles.shapeIcon} alt="" src="/shape.svg" />
        <img className={styles.shapeIcon} alt="" src="/shape.svg" />
      </div>
    </div>
  );
};

export default AboutContent5;
