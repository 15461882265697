import styles from "./TtpContent8.module.css";

const TtpContent8 = () => {
  return (
    <div className={styles.numbers}>

      <div className={styles.steps}>
        <div className={styles.divider} />
        <div className={styles.step}>
          <div className={styles.text}>
              <div className={styles.lmstextbox}>

     <div className={styles.boxcontainer}>
         <form>
          <input type="text" required placeholder="Full Name" name="name" />
          <input type="text" required placeholder="Phone Number" name="name" />
          <input type="text" required placeholder="Your School" name="name" />
          <input type="text" required placeholder="Role In Your School" name="name" /> 
          <input type="submit" value="Start Learning" />
         </form>      
     </div>

              </div>
          </div>

          <div className={styles.boxcontainerImg}>
          <img
            className={styles.illustrationIcon}
            alt=""
            src="/ttpicon01.svg"
          />
          </div>
        </div>


      </div>
    </div>
  );
};

export default TtpContent8;