import styles from "./SchoolContent9.module.css";

const SchoolContent9 = () => {
  return (
    <div className={styles.div}>
      <h1>Ready to Empower the Learner Experience?</h1>
      <div className={styles.button}>Lets Talk</div>
    </div>
  );
};

export default SchoolContent9;