import styles from "./CommonContent3.module.css";

const CommonContent3 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/unioncommon1.svg" />
      <img className={styles.unionIcon2} alt="" src="/union05.svg" />
      <div className={styles.ourProcess}>Sociol emotional learning program</div>
      <div className={styles.steps}>
        <div className={styles.divider} />
        <div className={styles.step}>
          <div className={styles.text}>
            <div className={styles.textInner}>
              <div className={styles.parent}>
                <div className={styles.div}> </div>
                <a className={styles.learning} href="/sel" style={{textDecoration:'none'}}>Know More</a>
              </div>
            </div>
            <div className={styles.immersiveLearningExperience}>
              SEL is the foundation for mental wellness.
              it equips children with the necessary skills to manage their emotions,
              set positive goals, show empathy, maintain relationships,
              and make responsible decisions.
            </div>
            <div className={styles.withEmotioanalConnect}>
              He final outcome which children would experience themselves
              <ul>
              <li>Recognize (Recognize their feelings and their life challenges with accuracy )</li>
              <li>Reflect (Reflect on how it is impacting them and why it is happening )</li>
              <li>Resolve (Resolve and take positive action coping skills that work for them)</li>
              </ul>  
            </div>
          </div>

          <img
            className={styles.illustrationIcon}
            alt=""
            src="/parents-social-emotional.svg"
          />
        </div>


      </div>
    </div>
  );
};

export default CommonContent3;
