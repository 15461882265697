import styles from "./TtpContent5.module.css";

const TtpContent5 = () => {
  return (
    <div className={styles.divParent}>
      <div className={styles.div}>
        <div className={styles.thingsYourChild}>
          Program Module
        </div>
        <div className={styles.knowWhatMajor}>
          Follow our program module to understand our approach at fashioning teachers into the best Gurus of holistic education delivery.
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.section1}>



          <div className={styles.section2}>
            <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/ttpnum1.png"
            />
            <div className={styles.section3}>
              <div className={styles.header}>
                <div className={styles.lackOfQuality}>
                  Self-awareness- I
                </div>
              </div>
              <div className={styles.theCurrentEconomic}>
                Getting to know each other, Setting norms, Who I am ? Quadrant questionnaire
              </div>
            </div>
          </div>


          <div className={styles.section2}>
            <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/ttpnum2.png"
            />
            <div className={styles.section3}>
              <div className={styles.header}>
                <div className={styles.lackOfQuality}>
                  Self-awareness -iI
                </div>
              </div>
              <div className={styles.theCurrentEconomic}>
                How to building Trust Awareness of responsibility Influence & Integrity, Dream School
              </div>
            </div>
          </div>


          <div className={styles.section2}>
            <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/ttpnum3.png"
            />
            <div className={styles.section3}>
              <div className={styles.header}>
                <div className={styles.lackOfQuality}>
                  Teaching Competencies -I
                </div>
              </div>
              <div className={styles.theCurrentEconomic}>
                Purpose of Education, Defining Objectives Reflective teaching
              </div>
            </div>
          </div>



          <div className={styles.section2}>
            <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/ttpnum4.png"
            />
            <div className={styles.section3}>
              <div className={styles.header}>
                <div className={styles.lackOfQuality}>
                  Social emotional learning 
                </div>
              </div>
              <div className={styles.theCurrentEconomic}>
                Understanding the behavior and psychology of students and identify who needs further help
              </div>
            </div>
          </div>



          <div className={styles.section2}>
            <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/ttpnum5.png"
            />
            <div className={styles.section3}>
              <div className={styles.header}>
                <div className={styles.lackOfQuality}>
                  Group Awareness & Systems Awareness
                </div>
              </div>
              <div className={styles.theCurrentEconomic}>
                Identifying, understanding & connecting to stakeholders
                Learning styles and learning taxonomies
              </div>
            </div>
          </div>                    


          <div className={styles.section2}>
            <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/ttpnum6.png"
            />
            <div className={styles.section3}>
              <div className={styles.header}>
                <div className={styles.lackOfQuality}>
                  Teaching competencies -II
                </div>
              </div>
              <div className={styles.theCurrentEconomic}>
                Connecting to Students Student challenges & deriving Teacher Competency Framework (TCF)
              </div>
            </div>
          </div>  



          <div className={styles.section2}>
            <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/ttpnum7.png"
            />
            <div className={styles.section3}>
              <div className={styles.header}>
                <div className={styles.lackOfQuality}>
                  Teaching competencies -III
                </div>
              </div>
              <div className={styles.theCurrentEconomic}>
                Using technology for teaching and learning
                Management of class dynamics
              </div>
            </div>
          </div>  




        </div>

      </div>
    </div>
  );
};

export default TtpContent5;
