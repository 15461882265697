import styles from "./PartnerContent1.module.css";

const PartnerContent1 = () => {
  return (
    <div className={styles.divParent}>
      <div className={styles.div}>

        <b className={styles.noChildWillContainer}>
          <span>{`Grow your business along with a social impact`}</span>
        </b>

        <div className={styles.genxtureBringsTheContainer}>

          <span>{`We seek your partnership to push our mission forward and enable educators and institutes with required services to create inspiring learning outcomes.`}</span>

        </div>

        <div className={styles.a}>
          <div className={styles.startLearning}>Apply Now</div>
        </div>



      </div>
          <img className={styles.heroIcon} alt="" src="/partnerimg1.svg" />


    </div>
  );
};

export default PartnerContent1;
