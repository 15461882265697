import styles from "./SchoolContent3.module.css";

const SchoolContent3 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/union8.svg" />
      <img className={styles.unionIcon2} alt="" src="/union05.svg" />

      <div className={styles.steps}>

        <div className={styles.divider} />
        <div className={styles.step}>

          <img
            className={styles.illustrationIcon}
            alt=""
            src="/schoolimg2.svg"
          />
        </div>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Career Discovery  Program
          </div>
          <div className={styles.getThemReady}>
            Our approach to assessing students' skills is holistic, focusing on identifying and improving their unique potential through personalized assessments. We help students make informed career choices and provide tailored learning support to address their skill gaps. Our career discovery program includes a workshop on 21st-century skills, aptitude testing, and personalized sessions for each student to create a detailed career path
          </div>
          <div className={styles.LearningAppTypeBox}>
            <div className={styles.LearningAppTypeBox1}>
              <img
                alt=""
                src="/greencheck.svg"
              />
              <span className={styles.LearningAppTypeBoxText}>Unique Persona Analysis </span>
            </div>
            <div className={styles.LearningAppTypeBox2}>
              <img
                alt=""
                src="/greencheck.svg"
              />
              <span className={styles.LearningAppTypeBoxText}>AI powered stream & career Recommendation </span>
            </div>
          </div>
          <div className={styles.LearningAppTypeBox}>
            <div className={styles.LearningAppTypeBox1}>
              <img
                alt=""
                src="/greencheck.svg"
              />
              <span className={styles.LearningAppTypeBoxText}>Skill Analysis & Mapping Across 14 Different Skills </span>
            </div>
            <div className={styles.LearningAppTypeBox2}>
              <img
                alt=""
                src="/greencheck.svg"
              />
              <span className={styles.LearningAppTypeBoxText}>Learning Interventions Required</span>
            </div>
          </div>
          <div className={styles.LearningAppTypeBox}>
            <div className={styles.LearningAppTypeBox1}>
              <img
                alt=""
                src="/greencheck.svg"
              />
              <span className={styles.LearningAppTypeBoxText}>Strength Areas for Student</span>
            </div>
            <div className={styles.LearningAppTypeBox2}>
              <img
                alt=""
                src="/greencheck.svg"
              />
              <span className={styles.LearningAppTypeBoxText}>Aspects Requiring More Focus</span>
            </div>
          </div>
          <a href="Career" style={{ textDecoration: 'none',textAlign:'center',width:'100%' }}>
            <div style={{ width: '190px' }} className={styles.a}>
              <div style={{ fontSize: '18px' }} className={styles.startLearning}>Learn More</div>
            </div>
          </a>
        </div>

      </div>
    </div>
  );
};

export default SchoolContent3;
