import styles from "./LmsContent5.module.css";

const LmsContent5 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Actionable Analytics & Dashboard
          </div>
          <div className={styles.getThemReady}>
            <ul>
             <li>Keep track of students in real time with automated attendance.</li>
             <li> Intricate LMS tools to analyze and assess performance.</li>
             <li>Personalized strength and weakness reports to understand students.</li>
            </ul>  
          </div>

        </div>

        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/Lmsmodernart4.png"
          />
        </div>


      </div>
    </div>
  );
};

export default LmsContent5;
