import styles from "./TtpContent4.module.css";

const TtpContent4 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/ttpContent2.png"
          />

        </div>
        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Our Perception for teachers of the future
          </div>
          <div className={styles.getThemReady}>
            We believe that teachers can be the best Leaders of learning if the why, what and how are integrated in the teaching and curriculum.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/ttpicon5.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Observant of students' Cognitive skills.</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/ttpicon6.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Self aware and Self learning Attitude</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/ttpicon7.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Transform from content delivery to knowledge creator</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/ttpicon8.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Volunteer to learn and improve future of education</span>
               </div>
            </div>


        </div>
      </div>
    </div>
  );
};

export default TtpContent4;
