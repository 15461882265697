import Meta from "../components/Meta";
import Header from "../components/Header";
import AboutContent1 from "../components/AboutContent1";
import AboutContent2 from "../components/AboutContent2";
import AboutContent02 from "../components/AboutContent02";
import AboutContent4 from "../components/AboutContent4";
import AboutContent3 from "../components/AboutContent3";
import AboutContent5 from "../components/AboutContent5";
import Footer from "../components/Footer";

import styles from "./AboutUs.module.css";

const AboutUs = () => {
  return (
    <div className={styles.home}>
      <Header />
      <AboutContent1 />
      <AboutContent2 />
      <AboutContent02 />      
      <AboutContent4 />
      <AboutContent3 />
      <AboutContent5 />
      <Footer />
    </div>
  );
};

export default AboutUs;