import styles from "./SchoolContent6.module.css";

const SchoolContent6 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            GenVR Labs
          </div>
          <div className={styles.getThemReady}>
            Elevate your science education with Virtual Lab - a state-of-the-art platform that delivers immersive simulations and experiential learning. Our advanced content, developed with 3D and mixed reality technologies, enhances visual processing and promotes greater understanding of complex concepts.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Hands-on learning experience</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Best in-class STEM tools</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Accessible 24x7</span>
               </div>
            </div>
               <div style={{width: '190px'}} className={styles.a}>
                 <div style={{fontSize: '18px',textAlign:'center'}} className={styles.startLearning}>Learn More</div>
               </div> 
        </div>

      
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/schoolimg5.svg"
          />
        </div>

      </div>
    </div>
  );
};

export default SchoolContent6;
