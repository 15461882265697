import styles from "./SchoolContent8.module.css";

const SchoolContent8 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Teacher Transformation program
          </div>
          <div className={styles.getThemReady}>
             The Teacher Transformation Program is a professional development initiative that empowers educators to adapt to changing educational landscapes and meet the evolving needs of students. It equips teachers with the latest methodologies, instructional strategies, and assessment techniques, fostering a culture of continuous learning and collaboration. The program ensures quality teaching, selfawareness & emotional development, promotes inclusivity,  and enhances student success.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>D orci porta d</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>D orci porta d</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>D orci porta d</span>
               </div>
            </div>  
               <div style={{width: '190px'}} className={styles.a}>
                 <div style={{fontSize: '18px',textAlign:'center'}} className={styles.startLearning}>Learn More</div>
               </div> 
                    
        </div>

      
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/sel_n.jpg"
          />
        </div>

      </div>
    </div>
  );
};

export default SchoolContent8;
