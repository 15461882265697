import styles from "./SelContent3.module.css";

const SelContent3 = () => {
  return (
    <div className={styles.numbers}>

      <div className={styles.divParent}>
        <div className={styles.div}>
          <div className={styles.howIsGenxture}>
            What are some of the skills kids walk away with?
          </div>
          <div className={styles.SelPara}>
            What are some of the skills kids walk away with?
          </div>          
        </div>
        <div className={styles.divGroup}>
          <div className={styles.div1}>
              <img className={styles.selIcon} alt="" src="/selicon1.png" />
            <b className={styles.b}>
              <span className={styles.txt}>
                Resilient thoughts
              </span>
            </b>
            <div
              className={styles.ofAllWorking}
            >{`Cultivating mental toughness to thrive in the face of adversity.`}</div>
          </div>
          <div className={styles.div1}>
              <img className={styles.selIcon} alt="" src="/selicon2.png" />
            <b className={styles.b}>
              <span className={styles.txt}>
                  Mindfulness
              </span>
            </b>
            <div className={styles.ofAllWorking}>
              Encouraging to be present and aware of their thoughts and feelings.
            </div>
          </div>
          <div className={styles.div1}>
              <img className={styles.selIcon} alt="" src="/selicon3.png" />
            <b className={styles.b2}>
              Emotional Intelligence
            </b>
            <div className={styles.ofAllWorking}>
              Empowering with emotional intelligence for success in academics and life
            </div>
          </div>
          <div className={styles.div1}>
             <img className={styles.selIcon} alt="" src="/selicon4.png" />
            <b className={styles.b2}>
               Impulse Control
            </b>
            <div className={styles.ofAllWorking}>
              Helping manage their impulses and avoid impulsive behavior.
            </div>
          </div>
        </div>
      </div>


      <div className={styles.divParent}>
        <div className={styles.divGroup}>
          <div className={styles.div1}>
              <img className={styles.selIcon} alt="" src="/selicon5.png" />
            <b className={styles.b}>
              <span className={styles.txt}>
                Essential life skills
              </span>
            </b>
            <div
              className={styles.ofAllWorking}
            >{`Building competence and confidence with practical, real-world skills.`}</div>
          </div>
          <div className={styles.div1}>
              <img className={styles.selIcon} alt="" src="/selicon6.png" />
            <b className={styles.b}>
              <span className={styles.txt}>
                 Anxiety and panic relief
              </span>
            </b>
            <div className={styles.ofAllWorking}>
              Building resilience and self-efficacy through anxiety management.
            </div>
          </div>
          <div className={styles.div1}>
              <img className={styles.selIcon} alt="" src="/selicon7.png" />
            <b className={styles.b2}>
              Self-compassion
            </b>
            <div className={styles.ofAllWorking}>
              Building resilience and positive self-esteem through self-compassion practices.
            </div>
          </div>
          <div className={styles.div1}>
             <img className={styles.selIcon} alt="" src="/selicon8.png" />
            <b className={styles.b2}>
               Optimism mindset
            </b>
            <div className={styles.ofAllWorking}>
              Focus on the positive and overcome challenges with a can-do attitude
            </div>
          </div>
        </div>
      </div>





    </div>
  );
};

export default SelContent3;
