import styles from "./Footer.module.css";
import RegistrationPopup from "./RegistrationPopup";
import {
  Link,
} from "react-router-dom";

const Footer = () => {
  return (
    <div className={styles.div}>
      <div className={styles.div1}>
        <div className={styles.div2}>
          <b className={styles.connectWithUs}>CONNECT WITH US ON</b>
          <div className={styles.div3}>
            <a href="https://www.facebook.com/Genxture" target="_blank"><img className={styles.lpFbpngIcon} alt="" src="/lp-fbpng@2x.png" /></a>
            <a href="https://www.instagram.com/genxture_sel/" target="_blank"><img className={styles.lpInpngIcon} alt="" src="/lp-inpng@2x.png" /></a>
           {/* <img className={styles.lpTwpngIcon} alt="" src="/lp-twpng@2x.png" />*/}
          </div>
          <div className={styles.genxtureStandsForEducationParent}>
             <div className={styles.genxtureStandsFor}>
              <div>
              Genxture Edutech Pvt Ltd
              <br/>728 7th floor, DLF Cybercity, Patia
              <br/>Bhubaneswar, 751024, Odisha
              </div>
             
              <div style={{padding: '2px'}}></div>
              support@genxture.com
             </div>
            <div className={styles.genxtureStandsFor}>
              +91-9380203885
            </div>
          </div>
          
          <div className={styles.copyright2023}>
            Copyright © 2023 Genxture Inc
          </div>
        </div>
        <div className={styles.div4}>
          <b className={styles.company}>Company</b>
          <div className={styles.ul}>
          <Link to="/" style={{textDecoration:'none'}}><div className={styles.genxtureStandsFor}>Home</div></Link>
          <Link to="/AboutUs" style={{textDecoration:'none'}}><div className={styles.genxtureStandsFor}>About Us</div></Link>
             
            <div className={styles.genxtureStandsFor}><RegistrationPopup text="Contact Us" style1='' style2=''/></div>
          </div>
        </div>
        <div className={styles.div5}>
          <b className={styles.company}>Quick Links</b>
          <div className={styles.ulWrapper}>
            <div className={styles.ul1}>
              <Link to="/PrivacyPolicy" style={{textDecoration:'none'}}><div
                className={styles.genxtureStandsFor}
              >{`Privacy Policy`}</div></Link>
             <Link to="/Partner" style={{textDecoration:'none'}}><div className={styles.genxtureStandsFor}>  Partner with us</div></Link>
             <Link style={{textDecoration:'none'}}><div className={styles.genxtureStandsFor}>Terms & Condition</div></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
