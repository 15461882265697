import styles from "./SelContent2.module.css";

const SelContent2 = () => {
  
   return (
     <div className={styles.division1}>
         <p className={styles.division1Text}>
          “Social emotional learning teaches character and has been shown to improve academic, social, and professional achievement while preventing mental health disorders and violence. Research has demonstrated that social emotional competencies help children grow self-aware, confident, and empathetic, leading to improved behavior and test scores."
         </p>
         <p className={styles.division1Title}>
          Socio emotional Learning is Crucial - unicef
         </p>      
     </div>
   );

};

export default SelContent2;
