import styles from "./SchoolContent1.module.css";
import RegistrationPopupSchool from "./RegistrationPopupSchool";

const SchoolContent1 = () => {
  return (
    <div className={styles.divParent}>
          <img className={styles.heroIcon} alt="" src="/schoolimg.svg" />
      <div className={styles.div}>

        <b className={styles.noChildWillContainer}>
          <span>{`Empower Your School With `}</span>
          <span className={styles.emotionalIntelligence}>
            Genxture
          </span>
        </b>

        <div className={styles.genxtureBringsTheContainer}>

          <span>{`Unlocking the true potential of education with cutting edge technology and culture focused on holistic engagement, well-Being, and belonging`}</span>

        </div>

        <div style={{width:'100%'}}>
          <div className><RegistrationPopupSchool text="Let's Talk" style1={styles.a} style2={styles.startLearning}/></div>
        </div>

        <div style={{display: 'none' /*App*/}}  className={styles.div1}>
          <b className={styles.downloadApp}>Download App:</b>
          <div className={styles.playStoresvgParent}>
            <img
              className={styles.playStoresvgIcon}
              alt=""
              src="/play-storesvg.svg"
            />
            <img
              className={styles.appStoresvgIcon}
              alt=""
              src="/app-storesvg.svg"
            />
          </div>
        </div>

      </div>


    </div>
  );
};

export default SchoolContent1;
