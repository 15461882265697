import styles from "./CareerContent4.module.css";

const CareerContent4 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/careerunion1.svg" />
      <img className={styles.unionIcon2} alt="" src="/aboutunion2.svg" />
      <div className={styles.ourCulture}>
        <div className={styles.theGenxtureEndeavor}>
          Career Discovery Program Video
        </div>
         
        <div className={styles.divParent}>
            <img className={styles.careeroverlay} alt="" src="/careeroverlay.png" />
        </div>
      </div>
            <img className={styles.unionIconComponent} alt="" src="/compo.svg" />
    </div>


    
  );
};

export default CareerContent4;
