import styles from "./LmsContent2.module.css";

const LmsContent2 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.LmsContentHead}>
        <div className={styles.LmsContentHeadTitle}>Simplify your institute's operations and maximize efficiency with our intelligent platform</div>
      </div>

      <div className={styles.frameParent}>
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/Lmsmodernart1.png"
          />
        </div>
        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Modern Classrooms for the Modern Age
          </div>
          <div className={styles.getThemReady}>
            <ul>
             <li>Tackle classroom engagement with Live Polls & Hand Raise features.</li>
             <li>Revisit classes using the Live Class Recording feature.</li>
             <li>Manage classroom schedule and timetable effortlessly</li>
            </ul>  
          </div>

        </div>
      </div>
    </div>
  );
};

export default LmsContent2;
