import styles from "./LearningAppContent3.module.css";

const LearningAppContent3 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            All classes, all subjects, all boards
          </div>
          <div className={styles.getThemReady}>
            You will find everything related to your curriculum here. We believe in a simplified approach to learning and the Genxture reflects just that.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/learningappicon5.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Science and Math</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/learningappicon6.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Cognitive skills</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/learningappicon7.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}> Art and Literature</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/learningappicon8.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Knowledge and Awareness </span>
               </div>
            </div>
        </div>

      
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/LearningAppPhone2.svg"
          />
        </div>

      </div>
    </div>
  );
};

export default LearningAppContent3;
