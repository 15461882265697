import styles from "./SchoolContent2.module.css";

const SchoolContent2 = () => {
   return (
      <div className={styles.features}>

         <div className={styles.frameParent}>

            <div className={styles.div}>
               <div className={styles.helpYourChild}>
                  Socio emotional learning
               </div>
               <div className={styles.getThemReady}>
                  SEL skills and behavior are interrelated. SEL is an educational approach that teaches students to identify and manage emotions, build positive relationships, and make responsible choices within a social setting. SEL instruction helps students become more resilient in both their daily lives and academic pursuits. By regulating their emotions, students are more likely to exhibit positive behaviors and which is ulmately reflect on acedemic sucess.
               </div>
               <div className={styles.LearningAppTypeBox}>
                  <div className={styles.LearningAppTypeBox1}>
                     <img
                        alt=""
                        src="/greencheck.svg"
                     />
                     <span className={styles.LearningAppTypeBoxText}>Focus and concentration</span>
                  </div>
                  <div className={styles.LearningAppTypeBox2}>
                     <img
                        alt=""
                        src="/greencheck.svg"
                     />
                     <span className={styles.LearningAppTypeBoxText}>Managing emotions</span>
                  </div>
               </div>
               <div className={styles.LearningAppTypeBox}>
                  <div className={styles.LearningAppTypeBox1}>
                     <img
                        alt=""
                        src="/greencheck.svg"
                     />
                     <span className={styles.LearningAppTypeBoxText}>Managing impatience</span>
                  </div>
                  <div className={styles.LearningAppTypeBox2}>
                     <img
                        alt=""
                        src="/greencheck.svg"
                     />
                     <span className={styles.LearningAppTypeBoxText}>Managing panic attach</span>
                  </div>
               </div>
               <div className={styles.LearningAppTypeBox}>
                  <div className={styles.LearningAppTypeBox1}>
                     <img
                        alt=""
                        src="/greencheck.svg"
                     />
                     <span className={styles.LearningAppTypeBoxText}>Anger management</span>
                  </div>
                  <div className={styles.LearningAppTypeBox2}>
                     <img
                        alt=""
                        src="/greencheck.svg"
                     />
                     <span className={styles.LearningAppTypeBoxText}>growth mindset</span>
                  </div>
               </div>
               <div className={styles.LearningAppTypeBox}>
                  <div className={styles.LearningAppTypeBox1}>
                     <img
                        alt=""
                        src="/greencheck.svg"
                     />
                     <span className={styles.LearningAppTypeBoxText}>Antibullying</span>
                  </div>
                  <div className={styles.LearningAppTypeBox2}>
                     <img
                        alt=""
                        src="/greencheck.svg"
                     />
                     <span className={styles.LearningAppTypeBoxText}>Sustainable living</span>
                  </div>
               </div>
               <a href="sel" style={{textDecoration:'none',textAlign:'center',width:'100%'}}>
                  <div style={{ width: '190px' }} className={styles.a}>
                     <div style={{ fontSize: '18px' }} className={styles.startLearning}>Learn More</div>
                  </div>
               </a>
            </div>


            <div className={styles.screenChangeMeParent}>
               <img
                  className={styles.screenChangeMe}
                  alt=""
                  src="/schoolimg1.svg"
               />
            </div>

         </div>
      </div>
   );
};

export default SchoolContent2;
