import styles from "./CareerContent6.module.css";

const CareerContent6 = () => {

  return (
    <div className={styles.divParent}>


      <div className={styles.slider}>
        <div className={styles.imageParent}>

          <div className={styles.sliderBtns}>
            <div className={styles.prevBtn}>
              <img className={styles.chevronIcon} alt="" src="/chevron.svg" />
            </div>
            <div className={styles.prevBtn}>
              <img className={styles.chevronIcon} alt="" src="/chevron1.svg" />
            </div>
          </div>
        </div>
        
        <div className={styles.quotesParent}>
          <img className={styles.quotesIcon} alt="" src="/quotes.svg" />
          <div className={styles.textParent}>
            <i className={styles.text}>
              Vestibulum nunc lectus auctor quis. Natoque lectus tortor lacus, eu. Nunc feugiat nisl maecenas nulla hac morbi. Vitae, donec facilisis sed nunc netus. Venenatis posuere faucibus enim est. Vel dignissim morbi blandit morbi tellus. Arcu ullamcorper quis enim.
            </i>
            <b className={styles.fatherOfAarav}>Rekha Singh</b>
            <div className={styles.text1}>Class 6th student, Jyoti High sec. School. Korba</div>
          </div>
          <div className={styles.slider1}>
            <div className={styles.shape} />
            <img className={styles.shapeIcon} alt="" src="/shape.svg" />
            <img className={styles.shapeIcon} alt="" src="/shape.svg" />
          </div>
        </div>

          <img className={styles.imageIcon} alt="" src="/studenttestimonial.svg" />


      </div>
    </div>
  );
};

export default CareerContent6;
