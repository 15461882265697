import Meta from "../components/Meta";
import Header from "../components/Header";
import LmsContent1 from "../components/LmsContent1";
import LmsContent2 from "../components/LmsContent2";
import LmsContent3 from "../components/LmsContent3";
import LmsContent4 from "../components/LmsContent4";
import LmsContent5 from "../components/LmsContent5";
import LmsContent6 from "../components/LmsContent6";
import LmsContent7 from "../components/LmsContent7";
import LmsContent8 from "../components/LmsContent8";

import Footer from "../components/Footer";
import styles from "./Parents.module.css";

const Lms = () => {
  return (

    <div className={styles.home}>
      <div className={styles.hero}>
        <img className={styles.maskGroupIcon} alt="" src="/mask-group@2x.webp" />
        <img className={styles.frameIcon} alt="" src="/frame.svg" />
        <Header />
        <LmsContent1 />
      </div>
      <LmsContent2 />
      <LmsContent3 />
      <LmsContent4 />
      <LmsContent5 />
      <LmsContent6 />      
      <LmsContent7 />
      <LmsContent8 />
      <Footer />
    </div>
  );
};

export default Lms;
