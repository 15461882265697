import styles from "./PartnerContent5.module.css";

const PartnerContent5 = () => {
  
   return (

     <div className={styles.boxcontainer}>
         <form>
          <input type="text" placeholder="Full Name" name="name" />
          <input type="text" placeholder="Phone Number" name="name" />
          <input type="text" placeholder="Email Address" name="name" />
          <input type="text" placeholder="Job Title" name="name" /> 
          <input type="text" placeholder="Nature of Business" name="name" />                   
          <input type="submit" value="Apply" />
         </form>      
     </div>
   );

};

export default PartnerContent5;
