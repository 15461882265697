import styles from "./SelContent5.module.css";

const SelContent5 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Managing Impatience
          </div>
          <div className={styles.getThemReady}>
            A generation used to instant gratification, over-stimulation and virtual simulation results in poor social and emotional behavior, leading to poor mental health. This stems from a lack of waiting habits, constant over-abundance and reliance on gadgets.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-5.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Wise decisions</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-6.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Success predictor</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-7.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Character development</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-8.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Friendship builder</span>
               </div>
            </div>
        </div>

      
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/selimg-2.svg"
          />
        </div>

      </div>
    </div>
  );
};

export default SelContent5;
