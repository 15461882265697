import styles from "./LmsContent3.module.css";

const LmsContent3 = () => {
  return (
    <div className={styles.features}>

      <div className={styles.frameParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Effortless Student Engagement
          </div>
          <div className={styles.getThemReady}>
            <ul>
             <li>Create, conduct & evaluate tests with ease.</li>
             <li>Assign and keep track of student progress with homework.</li>
             <li>Relay relevant information through study materials.</li>
            </ul>  
          </div>

        </div>

        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/Lmsmodernart2.png"
          />
        </div>


      </div>
    </div>
  );
};

export default LmsContent3;
