import styles from "./HomeContent6.module.css";
import { useState, useEffect, useRef  } from "react";
import $ from 'jquery';
import RegistrationPopup from "./RegistrationPopup";


const HomeContent6 = () => {

    const [displayA, setDisplayA] = useState(true);
    const [displayFocus, setDisplayFocus] = useState(true);
    const [displayB, setDisplayB] = useState(false);
    const [displayC, setDisplayC] = useState(false);

    const showA = () => {
      setDisplayA(true);
      setDisplayB(false);
      setDisplayC(false);
      setDisplayFocus(false);
    }
    const showB = () => {
      setDisplayA(false);
      setDisplayB(true);
      setDisplayC(false);
      setDisplayFocus(false);
    };
    const showC = () => {
      setDisplayA(false);
      setDisplayB(false);
      setDisplayC(true);
      setDisplayFocus(false);
    };


  // const inputRef = useRef(null);

  // useEffect(() => {
  //   inputRef.current.focus();
  // }, []);



  //const divRef = useRef();

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (divRef.current && !divRef.current.contains(event.target)) {
  //       // Clicked outside the div, handle the focus logic here
  //       inputRef.current.focus();
  //     } else {

  //     }
  //   };

  //   document.addEventListener('click', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

  return (


    <div className={styles.features}>


      <div className={styles.genextureProvesIts}>
        Genxture Proves It's worth
      </div>
      <div className={styles.tabs}>
        <div tabIndex="1" onClick={showA} className={ (displayFocus ? styles.a_focus :styles.a )}>
          <div className={styles.knowledge}>Experience</div>
        </div>
        <div tabIndex="1" onClick={showB} className={styles.a1}>
          <div className={styles.knowledge}>Effectiveness</div>
        </div>
        <div tabIndex="1" onClick={showC} className={styles.a2}>
          <div className={styles.knowledge}>
            Enlivening
          </div>
        </div>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/screen-change-me@2x.webp"
          />
          <img
            className={styles.materialSymbolsplayCircleOIcon}
            alt=""
            src="/materialsymbolsplaycircleoutlinerounded.svg"
          />
        </div>

      {displayA &&<div className={styles.divContainer}> 
        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Counsel from the best
          </div>
          <div className={styles.getThemReady}>
            Get guidance from the top 1%
          </div>
          <div className>
            <div className><RegistrationPopup text="Know More" style1={styles.a3} style2={styles.knowMore}/></div>
          </div>
        </div>
        </div>}

       {displayB &&<div className={styles.divContainer1}>  
        <div className={styles.div1}>
          <div className={styles.helpYourChild}>
            Scientific backed and proven technique
          </div>
          <div className={styles.getThemReady}>
             Technique that works best for your scenario 
          </div>
          <div className>
            <div className><RegistrationPopup text="Know More" style1={styles.a3} style2={styles.knowMore}/></div>
          </div>
        </div>
        </div>}

       {displayC && <div className={styles.divContainer2}>   
        <div className={styles.div2}>
          <div className={styles.helpYourChild}>
            Captivating session
          </div>
          <div className={styles.getThemReady}>
            We make learning fun for kids by using 
            exciting stories that are suitable for their age and set in a familiar environment that they can relate.
          </div>
          <div className>
            <div className><RegistrationPopup text="Know More" style1={styles.a3} style2={styles.knowMore}/></div>
          </div>
        </div>
       </div>}

      </div>
    </div>
  );
};

export default HomeContent6;
