import styles from "./CareerContent1.module.css";
import RegistrationPopup from "./RegistrationPopup";

const CareerContent1 = () => {
  return (
    <div className={styles.divParent}>

      <div className={styles.aline}>
        <div className={styles.startLearningLMS}>Learning Management System</div>
      </div>

      <img className={styles.heroIcon} alt="" src="/careerimg1.png" />


      <div className={styles.div}>

        <b className={styles.noChildWillContainer}>
          <span>{`Discover Your`}</span>
          <span className={styles.emotionalIntelligence}>
            &nbsp;Perfect Career
          </span>
        </b>

        <div className={styles.genxtureBringsTheContainer}>

          <span>{`Make intelligent decisions about your future profession with our AI-enabled career guidance tools and expertise of our career counselors`}</span>

        </div>

        <div style={{ width: '100%', }}>
          <a href="https://www.careertest.genxture.com/" style={{textDecoration:'none', color:'#fff'}}>
          <div className={styles.a}>
            <div className={styles.startLearning}>Career Test </div>
          </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CareerContent1;
