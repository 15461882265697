import styles from "./HeaderSection.module.css";

const HeaderSection = ({ studentParentId, studentParentCode }) => {
  return (
    <div className={styles.a}>
      <div className={styles.students}>{studentParentId}</div>
      {studentParentCode!=="" && <img className={styles.aafterIcon} alt="" src={studentParentCode} />}
      <div className={styles.text} />
    </div>
  );
};

export default HeaderSection;
