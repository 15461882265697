import styles from "./PartnerContent05.module.css";

const PartnerContent05 = () => {
  return (

      <div className={styles.partnerDiv}>
        <div className={styles.partnerThingsYourChild}>
          Together we can go farther
        </div>
        <div className={styles.partnerKnowWhatMajor}>
          Partner with our fast-growing Ed-tech company and thrive with us
        </div>
      </div>

  );
};

export default PartnerContent05;