import styles from "./CommonContent5.module.css";

const CommonContent5 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/unioncommon1.svg" />
      <img className={styles.unionIcon2} alt="" src="/union05.svg" />
      <div className={styles.ourProcess}>K12 Learning App</div>
      <div className={styles.steps}>
        <div className={styles.divider} />
        <div className={styles.step}>
          <div className={styles.text}>
            <div className={styles.textInner}>
              <div className={styles.parent}>
                <div className={styles.div}></div>
                <div className={styles.learning}>Know More</div>
              </div>
            </div>
            <div className={styles.immersiveLearningExperience}>
              Bring Classroom to your home/ Your classroom in your palms
            </div>
            <div className={styles.withEmotioanalConnect}>
               Let out smartphone/tab be your. The Genxture app enables you to attend your classes and finish your assignments whenever and wharever you want. 
              <ul>
              <li>Well organized resources build confidence at school</li>
              <li>Making revision simpler and less stressful</li>
              <li>Making exam prepration faster and simplified</li>
              </ul>  
            </div>
          </div>

          <img
            className={styles.illustrationIcon}
            alt=""
            src="/Parents-mobile-1.svg"
          />
        </div>


      </div>
    </div>
  );
};

export default CommonContent5;
