import styles from "./HomeContent1.module.css";
import RegistrationPopup from "./RegistrationPopup";

import {
  Link,
} from "react-router-dom";
const HomeContent1 = () => {
  return (
    <div className={styles.divParent}>
      <div className={styles.div}>
        <b className={styles.noChildWillContainer}>
          <span className={styles.emotionalIntelligence}>
            Unlocking
          </span>
          <span> your child's potential through a holistic approach to mental health and emotional well-being.</span>
        </b>
        <div className={styles.genxtureBringsTheContainer}>
          <span>{`Focus and concentration , Managing patience,Anger management, Anti bullying,Growth mindset`}</span>
        </div>
        <div style={{width:'100%'}}>
            <div ><RegistrationPopup text="Start Learning" style1={styles.a} style2={styles.startLearning}/></div>
          </div>
        
        <div style={{display: 'none' /*App*/}}  className={styles.div1}>
          <b className={styles.downloadApp}>Download App:</b>
          <div className={styles.playStoresvgParent}>
            <Link style={{textDecoration: 'none', color: 'white'}} to="https://play.google.com/store/apps/details?id=com.genexture"><img
              className={styles.playStoresvgIcon}
              alt=""
              src="/play-storesvg.svg"
            />
            </Link>
            <img
              className={styles.appStoresvgIcon}
              alt=""
              src="/app-storesvg.svg"
            />
          </div>
        </div>
      </div>

      <img className={styles.heroIcon} alt="" src="/hero.svg" />
    </div>
  );
};

export default HomeContent1;
