import styles from "./CareerContent2.module.css";

const CareerContent2 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon2} alt="" src="/Career/union4.svg" />
      <img className={styles.unionIcon1} alt="" src="/Career/union5.svg" />
      <div className={styles.divParent}>
        <div className={styles.div}>
          <div className={styles.howIsGenxture}>
            The <span className={styles.emotionalIntelligence}>PROBLEM</span> we are solving
          </div>

        </div>
        <div className={styles.divGroup}>
          <div className={styles.div1}>
            <b className={styles.b}>
              <span className={styles.txt}>
                <span>{`71 `}</span>
                <span className={styles.span}>%</span>
              </span>
            </b>
            <div
              className={styles.ofAllWorking}
            >{`Students are Unsure of Careers `}</div>
            <img className={styles.imgiconcareer} alt="" src="/imgiconcareer1.svg" />
          </div>
          <div className={styles.div1}>
            <b className={styles.b}>
              <span className={styles.txt}>
                <span>{`16 `}</span>
                <span className={styles.span}>%</span>
              </span>
            </b>
            <div className={styles.ofAllWorking}>
              Working professional are unhappy & does not like going to work
            </div>
            <img className={styles.imgiconcareer} alt="" src="/imgiconcarrer2.svg" />
          </div>
          <div className={styles.div1}>
            <b className={styles.b2}>
              <span>{`93 `}</span>
              <span className={styles.span}>%</span>
            </b>
            <div className={styles.ofAllWorking}>
              Students make wrong career choices in India
            </div>
            <img className={styles.imgiconcareer} alt="" src="/imgiconcareer3.svg" />
          </div>
          <div className={styles.div1}>
            <b className={styles.b2}>
              <span>{`42 `}</span>
              <span className={styles.span}>%</span>
            </b>
            <div className={styles.ofAllWorking}>
              Of urban 20-24 years old were unemployed 
            </div>
            <img className={styles.imgiconcareer} alt="" src="/imgiconcareer4.svg" />
          </div>



        </div>
      </div>
    </div>
  );
};

export default CareerContent2;
