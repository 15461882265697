import styles from "./AboutContent4.module.css";
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';

const AboutContent4 = () => {


    const [displayA, setDisplayA] = useState(true);
    const [displayFocus, setDisplayFocus] = useState(true);
    const [displayB, setDisplayB] = useState(false);
    const [displayC, setDisplayC] = useState(false);

    const showA = () => {
      setDisplayA(true);
      setDisplayB(false);
      setDisplayC(false);
      setDisplayFocus(false);
    }
    const showB = () => {
      setDisplayA(false);
      setDisplayB(true);
      setDisplayC(false);
      setDisplayFocus(false);
    };
    const showC = () => {
      setDisplayA(false);
      setDisplayB(false);
      setDisplayC(true);
      setDisplayFocus(false);
    };

 /* const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const divRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        // Clicked outside the div, handle the focus logic here
        inputRef.current.focus();
      } else {

      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
*/

  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/aboutunion-1.svg" />
      <img className={styles.unionIcon2} alt="" src="/aboutunion2.svg" />
      <div className={styles.ourCulture}>
        <div className={styles.theGenxtureEndeavor}>
          The Genxture Endeavor to Transform Education
        </div>
        <div  className={styles.tabs}>
          <div  tabIndex="1" onClick={showA} className={ (displayFocus ? styles.a_focus :styles.a )}>
            <div className={styles.valueThatDefines}><span className={styles.valuesThatDefines1}>Value&nbsp;</span> <span className={styles.valuesThatDefines2}>that defines us</span></div>
          </div>
          <div tabIndex="1" onClick={showB} className={styles.a1}>
            <div className={styles.valueThatDefines}>How we work</div>
          </div>
          <div tabIndex="1" onClick={showC} className={styles.a1}>
            <div className={styles.valueThatDefines}>How we lead</div>
          </div>
        </div>


      {displayA &&<div className={styles.MainParents}>       
        <div className={styles.divParent}>
          <div className={styles.div}>
            <img
              className={styles.divChild}
              alt=""
              src="/rectangle-18290@2x.png"
            />
            <div className={styles.curiosity}>Curiosity</div>
            <div className={styles.buildTrustingRelationships}>
              Encouraging curiosity to foster a love of learning and exploration.
            </div>
          </div>
          <div className={styles.div1}>
            <img
              className={styles.divChild}
              alt=""
              src="/aboutrectangle-182901@2x.png"
            />
            <div className={styles.curiosity}>Creativity</div>
            <div className={styles.buildTrustingRelationships}>
              Nurturing creativity to promote innovation and self-expression.
            </div>
          </div>
          <div className={styles.div2}>
            <img
              className={styles.divChild}
              alt=""
              src="/learningappicon22.png"
            />
            <div className={styles.curiosity}>Courage</div>
            <div className={styles.buildTrustingRelationships2}>
              Supporting courage to take risks and face challenges with resilience and determination.
            </div>
          </div>
        </div>
        <div className={styles.divParent}>
          <div className={styles.div3}>
            <img
              className={styles.divChild}
              alt=""
              src="/learningappiconimg.png"
            />
            <div className={styles.curiosity}>cognitivity</div>
            <div className={styles.buildTrustingRelationships}>
              Cultivating compassion to promote kindness and empathy towards others.
            </div>
          </div>
          <div className={styles.div4}>
            <img
              className={styles.divChild}
              alt=""
              src="/aboutrectangle12850.png"
            />
            <div className={styles.curiosity}>Compassion</div>
            <div className={styles.buildTrustingRelationships}>
              Building a sense of community to promote connection, belonging, and a spirit of cooperation.
            </div>
          </div>
        </div>
       </div>}

      {displayB &&<div className={styles.MainParents}>       
        <div className={styles.divParent}>
          <div className={styles.div}>
            <img
              className={styles.divChild}
              alt=""
              src="/aboutIcon101.png"
            />
            <div className={styles.curiosity}>Accessibility</div>
            <div className={styles.buildTrustingRelationships}>
              We provide mental health care remotely through our telehealth services, ensuring that individuals have access to help regardless of their location.
            </div>
          </div>

          <div className={styles.div1}>
            <img
              className={styles.divChild}
              alt=""
              src="/aboutIcon102.png"
            />
            <div className={styles.curiosity}>Affordability</div>
            <div className={styles.buildTrustingRelationships}>
              By combining community-based mental health services with technology, we make mental health care affordable for all.
            </div>
          </div>
          <div className={styles.div2}>
            <img
              className={styles.divChild}
              alt=""
              src="/aboutIcon103.png"
            />
            <div className={styles.curiosity}>Awareness</div>
            <div className={styles.buildTrustingRelationships2}>
              By educating students about mental health, we aim to reduce stigma and create an environment where seeking help is easier and less judgmental.
            </div>
          </div>
        </div>

        <div className={styles.divParent}>
          <div className={styles.div3}>
            <img
              className={styles.divChild}
              alt=""
              src="/aboutIcon104.png"
            />
            <div className={styles.curiosity}>Availability</div>
            <div className={styles.buildTrustingRelationships}>
              Our socio emotional learning program trains educators to identify students who may require mental health support and provide assistance promptly
            </div>
          </div>

        </div>
       </div>}


      {displayC &&<div className={styles.MainParents}>       
        <div className={styles.divParent}>
          <div className={styles.div}>
            <img
              className={styles.divChild}
              alt=""
              src="/aboutIcon105.png"
            />
            <div className={styles.curiosity}>Imagine the future</div>
            <div className={styles.buildTrustingRelationships}>
              SImplifying the complex -Implementing innovative practices and setting high standards for quality care. Aiming for extra ordinary -invest in R&D to improve practices 
            </div>
          </div>
          <div className={styles.div1}>
            <img
              className={styles.divChild}
              alt=""
              src="/aboutIcon106.png"
            />
            <div className={styles.curiosity}>Inspire the team </div>
            <div className={styles.buildTrustingRelationships}>
              By Connecting, coaching, empowering Finding  meaning and purpose  in work
            </div>
          </div>
          <div className={styles.div2}>
            <img
              className={styles.divChild}
              alt=""
              src="/aboutIcon107.png"
            />
            <div className={styles.curiosity}>Make it happen</div>
            <div className={styles.buildTrustingRelationships2}>
              Doing what matters Continuous learning
            </div>
          </div>
        </div>

       </div>}

      </div>
    </div>
  );
};

export default AboutContent4;
