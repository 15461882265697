import styles from "./SchoolContent5.module.css";

const SchoolContent5 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/union8.svg" />
      <img className={styles.unionIcon2} alt="" src="/union05.svg" />

      <div className={styles.steps}>

        <div className={styles.divider} />
        <div className={styles.step}>

          <img
            className={styles.illustrationIcon}
            alt=""
            src="/schoolimg4.svg"
          />
        </div>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            NEP Learning App- School
          </div>
          <div className={styles.getThemReady}>
           A new App under your school's brand with sophisticated 'Content Usage' and 'Weak Area Identification' tools. Spellbound Parents by ultimate level of monitoring and individualized attention. The school's brand will be highlighted everywhere with app download available on play store.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Competitive Advantage</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Bundled Smart Class Content</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/greencheck.svg"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Bundled Smart Class Content </span>
               </div>
            </div>

               <div style={{width: '190px'}} className={styles.a}>
                 <div style={{fontSize: '18px',textAlign:'center'}} className={styles.startLearning}>Learn More</div>
               </div> 

        </div>

      </div>
    </div>
  );
};

export default SchoolContent5;
