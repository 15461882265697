import styles from "./TtpContent7.module.css";

import React,{useState} from 'react'


const TtpContent7 = () => {


  return (

    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/union2.svg" />
      <img className={styles.unionIcon2} alt="" src="/union6.svg" />
      <div className={styles.div}>
        <div className={styles.whyGenxture}>Transformational Shift In Teacher</div>
          <div className={styles.smallContent}>The Teachers Transformation Programs produces impeccable results in the form of unmatched quality development</div>
        </div>
    <div className={styles.div0}>
      <div className={styles.div1}>
        <div className={styles.div2}>

           <div className={styles.connect}>Aware</div>
           <div className={styles.buildTrustingRelationships}>
             Being aware and creating space for continuous learning and adaptability.
           </div>


        <div className={styles.div3}>1</div>
        </div>
        <div tabIndex="5" className={styles.div4}>
          <div className={styles.div02}>        
            <h1 className={styles.connectHide}>Skilled</h1>
              <div className={styles.buildTrustingRelationshipsHide}>
                <p className={styles.homeContentOpacity}>Leveraging the skills of 21st century to deliver the best learning experience.</p>
              </div>
             </div> 
          <div className={styles.div5}>2</div>
        </div>
        <div tabIndex="6" className={styles.div6}>
          <div className={styles.div02}>        
            <h1 className={styles.connectHide}>Observant</h1>
              <div className={styles.buildTrustingRelationshipsHide}>
               <p className={styles.homeContentOpacity}>Leaning on building trust and awareness to connect better with students.</p>
            </div>
          </div>  
          <div className={styles.div7}>3</div>
        </div>
        <div tabIndex="7" className={styles.div8}>
           <div className={styles.div02}>        
            <h1 className={styles.connectHide}>Tech Driven</h1>
              <div className={styles.buildTrustingRelationshipsHide}>
               <p className={styles.homeContentOpacity}>Utilizing technology for for teaching and Managing class dynamics</p>
            </div>
           </div> 
          <div className={styles.div9}>4</div>
        </div>
        <div tabIndex="8" className={styles.div10}>
          <div className={styles.div02}>        
            <h1 className={styles.connectHide}>No Boundaries</h1>
              <div className={styles.buildTrustingRelationshipsHide}>
               <p className={styles.homeContentOpacity}>Exploring latest methds of teaching and cancelling traditional limitations.</p>
            </div>
          </div>  
          <div className={styles.div11}>5</div>
        </div>

        <div className={styles.div41}>
          <div className={styles.div5}>1</div>
        </div>
        <div className={styles.div62}>
          <div className={styles.div7}>1</div>
        </div>
        <div className={styles.div83}>
          <div className={styles.div9}>1</div>
        </div>
        <div className={styles.div104}>
          <div className={styles.div11}>1</div>
        </div>


      </div>
    </div>
    </div>
  );
};

export default TtpContent7;
