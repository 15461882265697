import Meta from "../components/Meta";
import Header from "../components/Header";
import SelContent1 from "../components/SelContent1";
import SelContent2 from "../components/SelContent2";
import SelContent3 from "../components/SelContent3";
import SelContent4 from "../components/SelContent4";
import SelContent5 from "../components/SelContent5";
import SelContent6 from "../components/SelContent6";
import SelContent7 from "../components/SelContent7";
import SelContent8 from "../components/SelContent8";
import SelContent9 from "../components/SelContent9";
import SelContent10 from "../components/SelContent10";
import SelContent11 from "../components/SelContent11";
import SelContent12 from "../components/SelContent12";

import Footer from "../components/Footer";
import styles from "./Sel.module.css";

const Sel = () => {
  return (

    <div className={styles.home}>
      <div className={styles.hero}>
        <img className={styles.maskGroupIcon} alt="" src="/mask-group@2x.webp" />
        <img className={styles.frameIcon} alt="" src="/frame.svg" />
        <Header />
        <SelContent1 />
      </div>
      <SelContent2 />
      <SelContent3 />
      <SelContent4 />
      <SelContent5 />
      <SelContent6 /> 
      <SelContent7 />
      <SelContent8 />  
      <SelContent9 />                                                                  
      <SelContent10 />
      <SelContent11 />
      <SelContent12 />
      <Footer />
    </div>
  );
};

export default Sel;
