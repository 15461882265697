import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Link,
} from "react-router-dom";
import React from 'react'
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Parents from "./pages/Parents";
import LearningApp from  "./pages/LearningApp";
import Lms from "./pages/Lms";
import Ttp from "./pages/Ttp";
import Career from "./pages/Career";
import Sel from "./pages/Sel";
import School from "./pages/School";
import Partner from "./pages/Partner";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useEffect } from "react";


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "Genxture";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Genxture";
        metaDescription = "";
        break;
      case "/AboutUs":
        title = "";
        metaDescription = "";
        break;
      case "/Parents":
        title = "";
        metaDescription = "";
        break;
      case "/LearningApp":
        title = "";
        metaDescription = "";
        break;
      case "/Lms":
        title = "";
        metaDescription = "";
        break;
      case "/Ttp":
        title = "";
        metaDescription = "";
        break;
      case "/Career":
        title = "";
        metaDescription = "";
        break;
      case "/Sel":
        title = "";
        metaDescription = "";
        break;  
      case "/School":
        title = "";
        metaDescription = "";
        break;
      case "/Partner":
        title = "";
        metaDescription = "";
        break;
      case "/PrivacyPolicy":
        title = "";
        metaDescription = "";
        break;
      case "/RegistrationPopup":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route path="/Parents" element={<Parents />} />
      <Route path="/LearningApp" element={<LearningApp />} />
      <Route path="/Lms" element={<Lms />} />
      <Route path="/Ttp" element={<Ttp />} />
      <Route path="/Career" element={<Career />} />
      <Route path="/Sel" element={<Sel />} />
      <Route path="/School" element={<School />} />
      <Route path="/Partner" element={<Partner />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
    </Routes>
  );
}
export default App;
