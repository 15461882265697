import styles from "./HomeContent4.module.css";
import React,{useState} from 'react'
import $ from 'jquery';


const HomeContent4 = () => {


  return (
    <div className={styles.divParent}>



      <div className={styles.div}>
        <div className={styles.ourGroundbreakingApproach}>
          Our Groundbreaking Approach
        </div>
        <div className={styles.revolutionizingEducationRequ}>
          Small Steps, Big Impact: Revolutionizing Education Requires takes just 10 minutes everyday.
        </div>
      </div>

      
      <div className={styles.div1}>
        <div className={styles.div2}>
          <div className={styles.learningpngParent}>
            <img
              className={styles.learningpngIcon}
              alt=""
              src="/homegenxtureicon.png"
            />
            <div className={styles.animatedLearningModulesParent}>
              <i className={styles.animatedLearningModules}>
                Accessibility
              </i>
              <div className={styles.weHaveMade}>
                We offer remote mental health care through our telehealth services, so everyone can get help, no matter where they are.
              </div>
            </div>
          </div>
        </div>
        <div tabIndex="1" className={styles.div3}>
           <img className={styles.div3Image} alt="" src="/div3Home.svg"/>
           <i className={styles.experience}>Affordability</i>
        </div>
        <div tabIndex="1" className={styles.div4}> 
           <img className={styles.div3Image} alt="" src="/div4Home.svg"/>
          <i className={styles.experience}>Awareness</i>
        </div>
        <div tabIndex="1" className={styles.div5}>
           <img className={styles.div3Image} alt="" src="/div5Home.svg"/>
          <i className={styles.experience}>Availability</i>
        </div>

        <div className={styles.div31}>
          <i className={styles.experience}>Accessibility</i>
        </div>
        <div className={styles.div42}>
          <i className={styles.experience}>Accessibility</i>
        </div>
        <div className={styles.div53}>
          <i className={styles.experience}>Accessibility</i>
        </div>

      </div>
    </div>
  );
};

export default HomeContent4;
