import styles from "./SelContent8.module.css";

const SelContent8 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon} alt="" src="/union8.svg" />
      <div className={styles.ourProcess}>K12 Learning App</div>
      <div className={styles.steps}>

        <div className={styles.divider} />
        <div className={styles.step}>

          <img
            className={styles.illustrationIcon}
            alt=""
            src="/selimg6.svg"
          />
        </div>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Anti bullying
          </div>
          <div className={styles.getThemReady}>
            Teaching children about the harmful effects of bullying and how to recognize, prevent, and respond to bullying behavior, through interactive activities and discussions, creating a safe and supportive environment for all.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-17.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Creating Safe Spaces</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-18.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Coping strategy</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-19.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Recognizing Bullying</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-20.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Standing Up to Bullies</span>
               </div>
            </div>

        </div>

      </div>
    </div>
  );
};

export default SelContent8;
