import styles from "./LearningAppContent8.module.css";

import React,{useState} from 'react'


const LearningAppContent8 = () => {


  return (

    <div className={styles.numbers}>
      <img className={styles.unionIcon1} alt="" src="/union2.svg" />
      <img className={styles.unionIcon2} alt="" src="/union6.svg" />
      <div className={styles.div}>
        <div className={styles.whyGenxture}>What extra do you get with us</div>
          <div className={styles.smallContent}>We are different. You get what your school fails to offer. We prepare you for the uncertain.</div>
        </div>
    <div className={styles.div0}>
      <div className={styles.div1}>
        <div className={styles.div2}>

           <div className={styles.connect}>One Platform, Many Aspects</div>
           <div className={styles.buildTrustingRelationships}>
             At Genxture you get Holistic Learnin
           </div>


        <div className={styles.div3}>1</div>
        </div>
        <div tabIndex="5" className={styles.div4}>
          <div className={styles.div02}>        
            <h1 className={styles.connectHide}></h1>
              <div className={styles.buildTrustingRelationshipsHide}>
                <p className={styles.homeContentOpacity}></p>
              </div>
             </div> 
          <div className={styles.div5}>2</div>
        </div>
        <div tabIndex="6" className={styles.div6}>
          <div className={styles.div02}>        
            <h1 className={styles.connectHide}></h1>
              <div className={styles.buildTrustingRelationshipsHide}>
               <p className={styles.homeContentOpacity}></p>
            </div>
          </div>  
          <div className={styles.div7}>3</div>
        </div>
        <div tabIndex="7" className={styles.div8}>
           <div className={styles.div02}>        
            <h1 className={styles.connectHide}></h1>
              <div className={styles.buildTrustingRelationshipsHide}>
               <p className={styles.homeContentOpacity}></p>
            </div>
           </div> 
          <div className={styles.div9}>4</div>
        </div>
        <div tabIndex="8" className={styles.div10}>
          <div className={styles.div02}>        
            <h1 className={styles.connectHide}></h1>
              <div className={styles.buildTrustingRelationshipsHide}>
               <p className={styles.homeContentOpacity}></p>
            </div>
          </div>  
          <div className={styles.div11}>5</div>
        </div>

        <div className={styles.div41}>
          <div className={styles.div5}>1</div>
        </div>
        <div className={styles.div62}>
          <div className={styles.div7}>1</div>
        </div>
        <div className={styles.div83}>
          <div className={styles.div9}>1</div>
        </div>
        <div className={styles.div104}>
          <div className={styles.div11}>1</div>
        </div>


      </div>
    </div>
    </div>
  );
};

export default LearningAppContent8;
