import styles from "./TtpContent6.module.css";

const TtpContent6 = () => {
  return (
    <div className={styles.features}>

         <div className={styles.helpYourChildTitle}>
            Vision For Teachers
          </div>

      <div className={styles.frameParent}>

        <div className={styles.div}>
          <div className={styles.helpYourChildPara}>
            “Create Self-driven volunteer-led educator communities”
          </div>

          <div className={styles.TtpContainer}>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/ttpvision1.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Nurture life-long learning and awakening of teachers and learners</span>
               </div>

            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/ttpvision2.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Shape the future of teaching and the experience of learning</span>
               </div>
            </div>
            </div>
                  <img className={styles.TtpContainerImg}
                   alt=""
                   src="/ttpimage2.svg"
                  />

        </div>



      
        <div className={styles.screenChangeMeParent}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/ttpbusiness.png"
          />
        </div>

      </div>
    </div>
  );
};

export default TtpContent6;
