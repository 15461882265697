import styles from "./LearningAppContent1.module.css";
import RegistrationPopup from "./RegistrationPopup";

import {
  Link,
} from "react-router-dom";
const LearningAppContent1 = () => {
  return (
    <div className={styles.divParent}>
      <div className={styles.div}>

        <b className={styles.noChildWillContainer}>
          <span>{`Get better grades with ease using our learning app`}</span>
        </b>

        <div className={styles.genxtureBringsTheContainer}>

          <span>{`You will find all your study material, help and support here`}</span>

        </div>

        <div className style={{width:'100%'}}>
          <div className><RegistrationPopup text="Start Learning" style1={styles.a} style2={styles.startLearning}/></div>
        </div>

        {/*<div className={styles.div1}>

          <b className={styles.downloadApp}>Download App:</b>
          <div className={styles.playStoresvgParent}>
            <Link style={{textDecoration: 'none', color: 'white'}} to="https://play.google.com/store/apps/details?id=com.genexture"><img
              className={styles.playStoresvgIcon}
              alt=""
              src="/play-storesvg.svg"
            /></Link>
            <img
              className={styles.appStoresvgIcon}
              alt=""
              src="/app-storesvg.svg"
            />
          </div>
  </div>*/}

      </div>
      <img className={styles.heroIcon} alt="" src="/Learningappmobile.svg" />
    </div>
  );
};

export default LearningAppContent1;
