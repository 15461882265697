import Meta from "../components/Meta";
import Header from "../components/Header";
import PartnerContent1 from "../components/PartnerContent1";
import PartnerContent2 from "../components/PartnerContent2";
import PartnerContent3 from "../components/PartnerContent3";
import PartnerContent4 from "../components/PartnerContent4";
import PartnerContent05 from "../components/PartnerContent05";
import PartnerContent5 from "../components/PartnerContent5";

import Footer from "../components/Footer";
import styles from "./Parents.module.css";

const Partner = () => {
  return (

    <div className={styles.home}>
      <div className={styles.hero}>
        <img className={styles.maskGroupIcon} alt="" src="/mask-group@2x.webp" />
        <img className={styles.frameIcon} alt="" src="/frame.svg" />
        <Header />
        <PartnerContent1 />
      </div>
      <PartnerContent2 />
      <PartnerContent3 />
      <PartnerContent4 />
      <PartnerContent05 />            
      <PartnerContent5 />      
      <Footer />
    </div>
  );
};

export default Partner;
