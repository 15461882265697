import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Input from '@mui/joy/Input';
import Typography from '@mui/material/Typography';
import { colors } from '@mui/material';
import Alert from '@mui/joy/Alert';
import Box from '@mui/joy/Box';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#f2f2f2',
    '.MuiInput-root': {
      backgroundColor: '#fff',
    }
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },

  '& .MuiDialogTitle-root': {
    textAlign: 'center',
  },

}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function RegistrationPopup({ text, style1, style2 }) {
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [fail, setfail] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    message: '',
    mobile: '',
    pro: '',
    city: '',
    state: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const sendEmail = (event) => {
    const emailData = {
      name: formData.name,
      from: formData.email,
      mobile: formData.mobile,
      message: formData.message,
      pro: formData.pro,
      city: formData.city,
      state: formData.state
    };

    fetch('https://algoqube.com/email.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {

          console.log('Email sent successfully!');
          setFormData({
            name: '',
            email: '',
            message: '',
            mobile: '',
            pro: '',
            city: '',
            state: ''
          });
          setSuccess(true);
          // handleClose();
        } else {
          setfail(true);
          setfail(false);
          console.error('Failed to send the email:', data.error);
        }
      })
      .catch(error => {
        setfail(true);
        console.error('An error occurred:', error);
      });
    event.preventDefault();
  };

  return (
    <div >
      <div style={{ cursor: 'pointer' }} className={style1} onClick={handleClickOpen}>
        <div className={style2}>{text}</div>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Genxture Contact Us
          <Typography style={{ textAlign: 'center', fontSize: '12px' }}>
            support@genxture.com <br />+91-7848058919/ +91-9380203885
          </Typography>

        </BootstrapDialogTitle>

        <form
          onSubmit={sendEmail}
        >
          <DialogContent dividers>

            <div style={{ visibility: 'hidden' }}> text text text texttext texttext texttext texttext texttext texttext text</div>
            <Input name="name" onChange={handleInputChange} value={formData.name} placeholder="Full Name*" required sx={{ mb: 2, fontSize: 'var(--joy-fontSize-sm)' }} />
            <Input name="pro" onChange={handleInputChange} value={formData.pro} placeholder="Intrested In*" required sx={{ mb: 2, fontSize: 'var(--joy-fontSize-sm)' }} />

            <Input name="email" onChange={handleInputChange} value={formData.email} placeholder="Email Address*" required type="email" sx={{ mb: 2, padding: 1, fontSize: 'var(--joy-fontSize-sm)' }} />

            <Input name="mobile" onChange={handleInputChange} value={formData.mobile} placeholder="Mobile Number*" required type='number'
              sx={{ mb: 2, padding: 1, fontSize: 'var(--joy-fontSize-sm)' }} />
            <Input name="state" onChange={handleInputChange} value={formData.state} placeholder="City*" required sx={{ mb: 2, fontSize: 'var(--joy-fontSize-sm)' }} />
            <Input name="city" onChange={handleInputChange} value={formData.city} placeholder="State*" required sx={{ mb: 2, fontSize: 'var(--joy-fontSize-sm)' }} />

            <Input name="message" onChange={handleInputChange} value={formData.message} placeholder="Message, If any" sx={{ mb: 2, padding: 1, fontSize: 'var(--joy-fontSize-sm)' }} />

            {success && <Box sx={{ display: 'flex', gap: 2, width: '100%', flexDirection: 'column' }}>
              <Alert size="md" color="success">We have received your details and team will contact you soon .....</Alert>
            </Box>}

            {fail && <Box sx={{ display: 'flex', gap: 2, width: '100%', flexDirection: 'column' }}>
              <Alert color="danger" size="md">Some error occurred, please try again</Alert>
            </Box>}

          </DialogContent>
          <DialogActions>

            <Button type="submit">
              Submit
            </Button>
            <Button onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}