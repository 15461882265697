import Meta from "../components/Meta";
import Header from "../components/Header";
import TtpContent1 from "../components/TtpContent1";
import TtpContent2 from "../components/TtpContent2";
import TtpContent3 from "../components/TtpContent3";
import TtpContent4 from "../components/TtpContent4";
import TtpContent5 from "../components/TtpContent5";
import TtpContent6 from "../components/TtpContent6";
import TtpContent7 from "../components/TtpContent7";
import TtpContent8 from "../components/TtpContent8";

import Footer from "../components/Footer";
import styles from "./Parents.module.css";

const Ttp = () => {
  return (

    <div className={styles.home}>
      <div className={styles.hero}>
        <img className={styles.maskGroupIcon} alt="" src="/mask-group@2x.webp" />
        <img className={styles.frameIcon} alt="" src="/frame.svg" />
        <Header />
        <TtpContent1 />
      </div>
      <TtpContent2 />
      <TtpContent3 />
      <TtpContent4 />
      <TtpContent5 />
      <TtpContent6 />      
      <TtpContent7 />
      <TtpContent8 />
      <Footer />
    </div>
  );
};

export default Ttp;
