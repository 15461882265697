import styles from "./CommonContent1.module.css";
import RegistrationPopup from "./RegistrationPopup";
import {
  Link,
} from "react-router-dom";
const CommonContent1 = () => {
  return (
    <div className={styles.divParent}>
          <img className={styles.heroIcon} alt="" src="/ParentsImage1.svg" />
      <div className={styles.div}>

        <b className={styles.noChildWillContainer}>
          <span>{`10 minutes a day can set your child up for lifelong success`}</span>
        </b>

        <div className={styles.genxtureBringsTheContainer}>

          <span>{`Focus and concentration || Managing Impatience II Managing anger II Managing Panic attach II Anti bullying || Managing emotions`}</span>

        </div>

        <div className style={{width:'100%'}}>
          <div className><RegistrationPopup text="Start Learning" style1={styles.a} style2={styles.startLearning}/></div>
        </div>

        <div style={{display: 'none' /*App*/}} className={styles.div1}>
          <b className={styles.downloadApp}>Download App:</b>
          <div className={styles.playStoresvgParent}>
            <Link style={{textDecoration: 'none', color: 'white'}} to="https://play.google.com/store/apps/details?id=com.genexture"><img
              className={styles.playStoresvgIcon}
              alt=""
              src="/play-storesvg.svg"
            /></Link>
            <img
              className={styles.appStoresvgIcon}
              alt=""
              src="/app-storesvg.svg"
            />
          </div>
        </div>

      </div>


    </div>
  );
};

export default CommonContent1;
