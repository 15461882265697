import styles from "./AboutContent3.module.css";

const AboutContent3 = () => {
  return (
    <div className={styles.ourMission}>
      <div className={styles.ourMission1}>Our Story</div>
      <div className={styles.section}>
        <img
          className={styles.businessVisionPana1}
          alt=""
          src="/image102.png"
        />
        <div className={styles.text}>
          <div className={styles.wrapper}>

          </div>
          <i className={styles.makingEducationImpactful}>
            “Making education impactful and affordable.”
          </i>
          <div className={styles.technolgyHasEmpoweredContainer}>
            <p className={styles.technolgyHasEmpowered}>
              The issues we face as we grow up, such as "" conflict resolution"", ""saying no"", ""decision making"","" relationships"", ""self-doubt"", ""not feeling good enough"", ""depression"", and ""anxiety"", stem from a lack of knowledge and skills on how to deal with them. 
            </p>
            <p className={styles.technolgyHasEmpowered}>&nbsp;</p>
            <p className={styles.technolgyHasEmpowered}>
              While academic success is important, research shows that emotional development is critical for a fulfilled and meaningful life. 
            </p>
            <p className={styles.technolgyHasEmpowered}>&nbsp;</p>
            <p className={styles.technolgyHasEmpowered}>
             Genxture challenges traditional teaching methods by placing special focus on emotional development, including building self-awareness, empathy, conflict resolution, self-esteem, trust, and teamwork. 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent3;
