import Meta from "../components/Meta";
import Header from "../components/Header";
import CareerContent1 from "../components/CareerContent1";
import CareerContent2 from "../components/CareerContent2";
import CareerContent3 from "../components/CareerContent3";
import CareerContent4 from "../components/CareerContent4";
import CareerContent5 from "../components/CareerContent5";
import CareerContent6 from "../components/CareerContent6";
import CareerContent7 from "../components/CareerContent7";

import Footer from "../components/Footer";
import styles from "./Parents.module.css";

const Career = () => {
  return (

    <div className={styles.home}>
      <div className={styles.hero}>
        <img className={styles.maskGroupIcon} alt="" src="/mask-group@2x.webp" />
        <img className={styles.frameIcon} alt="" src="/frame.svg" />
        <Header />
        <CareerContent1 />
      </div>
      <CareerContent2 />
      <CareerContent3 />
      <CareerContent4 />
      <CareerContent5 />
      <CareerContent6 />
      <CareerContent7 />
      <Footer />
    </div>
  );
};

export default Career;
