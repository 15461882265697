import styles from "./PartnerContent3.module.css";

const PartnerContent3 = () => {
  return (
    <div className={styles.schoolContainer}>
      <img className={styles.schoolUnionIcon1} alt="" src="/union13.svg" />
      <img className={styles.schoolUnionIcon2} alt="" src="/union16.svg" />
      <div className={styles.schoolOurProcess}>Explore our partnership programs and find the one that’s right for you</div>
      <div className={styles.schoolSteps}>


      <div className={styles.frameParent}>
        <div className={styles.div1}>
          <div className={styles.helpYourChild}>
            Referral Partner Program
          </div>
          <div className={styles.getThemReady}>
            Leverage your network connect with your potential customer.
          </div>
            <div className={styles.a}>
             <div className={styles.startLearning}>Apply Now&nbsp;&nbsp;<img className={styles.arrow} alt="" src="/arrow.png" /></div>
            </div>
        </div>
      
        <div className={styles.screenChangeMeParent1}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/partnerimg2.png"
          />
        </div>
      </div>

      <div className={styles.frameParent}>

        <div className={styles.screenChangeMeParent2}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/partnerimg3.png"
          />
        </div>
        <div className={styles.div2}>
          <div className={styles.helpYourChild}>
            Sales Partner Program
          </div>
          <div className={styles.getThemReady}>
            Connect with us potential customer and support us in growing business.
          </div>
            <div className={styles.a}>
             <div className={styles.startLearning}>Apply Now&nbsp;&nbsp;<img className={styles.arrow} alt="" src="/arrow.png" /></div>
            </div>
        </div>
      
      </div>


      <div className={styles.frameParent}>
        <div className={styles.div3}>
          <div className={styles.helpYourChild}>
            Strategic Partner Program
          </div>
          <div className={styles.getThemReady}>
            Bring new customer onboard and serve them through out their journey.
          </div>
            <div className={styles.a}>
             <div className={styles.startLearning}>Apply Now&nbsp;&nbsp;<img className={styles.arrow} alt="" src="/arrow.png" /></div>
            </div>
        </div>
      
        <div className={styles.screenChangeMeParent3}>
          <img
            className={styles.screenChangeMe}
            alt=""
            src="/partnerimg4.png"
          />
        </div>
      </div>


      </div>
    </div>
  );
};

export default PartnerContent3;
