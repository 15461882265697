import styles from "./HomeContent3.module.css";

const HomeContent3 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon2} alt="" src="./union4.svg" />
      <img className={styles.unionIcon1} alt="" src="./union5.svg" />
      <div className={styles.divParent}>
        <div className={styles.div}>
          <div className={styles.howIsGenxture}>
            How is Genxture Changing things?
          </div>
          <div className={styles.weAtGenxture}>
            We at GenXture are trying to revolutionize education in accoradance
            with UNICEF's Socio-Emotional Learning (SEL) guidelines which effect
            a person's overall approach to education and mental strength.
          </div>
        </div>
        <div className={styles.divGroup}>
          <div className={styles.div1}>
            <b className={styles.b}>
              <span className={styles.txt}>
                <span>{`71 `}</span>
                <span className={styles.span}>%</span>
              </span>
            </b>
            <div
              className={styles.ofAllWorking}
            >{`of all Working Parents are not able to dedicate any time towards their children's growth. `}</div>
              <img className={styles.companyLogo} alt="" src="./comlogo1.png" />
          </div>
          <div className={styles.div1}>
            <b className={styles.b}>
              <span className={styles.txt}>
                <span>{`16 `}</span>
                <span className={styles.span}>%</span>
              </span>
            </b>
            <div className={styles.ofAllWorking}>
              1 out of 6 students in India is severly depressed due to studying
              too much.
            </div>
              <img className={styles.companyLogo} alt="" src="./comlogo2.png" />

          </div>
          <div className={styles.div1}>
            <b className={styles.b2}>
              <span>{`93 `}</span>
              <span className={styles.span}>%</span>
            </b>
            <div className={styles.ofAllWorking}>
              of Indian are not aware of more than 7 career Options.
            </div>
              <img className={styles.companyLogo} alt="" src="./comlogo3.png" />

          </div>
          <div className={styles.div1}>
            <b className={styles.b2}>
              <span>{`42 `}</span>
              <span className={styles.span}>%</span>
            </b>
            <div className={styles.ofAllWorking}>
              of students in India Face Bullying on a regular basis.
            </div>
              <img className={styles.companyLogo} alt="" src="./comlogo4.png" />

          </div>
          <div className={styles.div1}>
            <b className={styles.b2}>
              <span>{`92 `}</span>
              <span className={styles.span}>%</span>
            </b>
            <div className={styles.ofAllWorking}>
              <p className={styles.ofIndianStudents}>
                of Indian Students Don’t have Emotional Intelligence.
              </p>
            </div>
              <img className={styles.companyLogo} alt="" src="./comlogo5.png" />

          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContent3;
