import styles from "./HomeContent5.module.css";

const HomeContent5 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon} alt="" src="/union1.svg" />
      <div className={styles.frameContainer}>
       <img className={styles.Content5Image} alt="" src="/homeframe2.webp" />
      </div>
      <div className={styles.HomeContainerMain}>

      <div className={styles.ourProcess}>Our Process</div>
      <div className={styles.steps}>
        <div className={styles.divider} />
        <div className={styles.step}>
          <div className={styles.text}>
            <div className={styles.textInner}>
              <div className={styles.parent}>
                <div className={styles.div}>03</div>
                <div className={styles.learning}>Achieve</div>
              </div>
            </div>
            <div className={styles.withEmotioanalConnect}>
              Monitoring and evaluation are conducted to assess the effectiveness of the interventions through regular check-ins and tracking symptoms or evaluating changes in behavior.
            </div>
          </div>
          <div className={styles.div1}>
            <img className={styles.child} alt="" src="/ellipse-747.svg" />
            <img className={styles.item} alt="" src="/ellipse-748.svg" />
            <div className={styles.div2}>3</div>
          </div>
          <img
            className={styles.illustrationIcon}
            alt=""
            src="/illustration.svg"
          />
        </div>
        <div className={styles.step1}>
          <div className={styles.text}>
            <div className={styles.textInner}>
              <div className={styles.parent}>
                <div className={styles.div}>02</div>
                <div className={styles.learning}>Advice</div>
              </div>
            </div>

            <div className={styles.withEmotioanalConnect}>
              A implementation plan is developed and implemented through individual or group therapy sessions, participation in school-based interventions or programs or other forms of support.
            </div>
          </div>
          <div className={styles.div4}>
            <img className={styles.child} alt="" src="/ellipse-747.svg" />
            <img className={styles.item} alt="" src="/ellipse-7481.svg" />
            <div className={styles.div2}>2</div>
          </div>
          <img
            className={styles.illustrationIcon}
            alt=""
            src="/illustration1.svg"
          />
        </div>
        <div className={styles.step2}>
          <div className={styles.text2}>
            <div className={styles.textInner}>
              <div className={styles.parent}>
                <div className={styles.div}>01</div>
                <div className={styles.learning}>Assessment</div>
              </div>
            </div>

            <div className={styles.withEmotioanalConnect}>
              Provide Mental health care for students, the first step is to identify those who need support through education , observations, referrals, or assessments.
            </div>
          </div>
          <img
            className={styles.illustrationIcon}
            alt=""
            src="/illustration2.svg"
          />
          <div className={styles.div1}>
            <img className={styles.child} alt="" src="/ellipse-747.svg" />
            <img className={styles.item} alt="" src="/ellipse-7482.svg" />
            <div className={styles.div2}>1</div>
          </div>
        </div>
      </div>

</div>
    </div>
  );
};

export default HomeContent5;
