import styles from "./LmsContent1.module.css";
import RegistrationPopup from "./RegistrationPopup";

const LmsContent1 = () => {
  return (
    <div className={styles.divParent}>
      <div className={styles.div}>

        <div className={styles.aline}>
          <div className={styles.startLearningLMS}>Learning Management System</div>
        </div>

        <b className={styles.noChildWillContainer}>
          <span>{`Experience`}</span><span className={styles.emotionalIntelligence}>&nbsp;future of education&nbsp;</span><span>{`with integrated school platform`}</span>

        </b>

        <div className={styles.genxtureBringsTheContainer}>

          <span>{`Manage every need of your school on a single platform`}</span>

        </div>

        <div className style={{width:'100%'}}>
          <div className><RegistrationPopup text="Start Learning" style1={styles.a} style2={styles.startLearning}/></div>
        </div>



      </div>
      <img className={styles.heroIcon} alt="" src="/lmsImg1.png" />
    </div>
  );
};

export default LmsContent1;
