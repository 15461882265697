import Meta from "../components/Meta";
import Header from "../components/Header";
import SchoolContent1 from "../components/SchoolContent1";
import SchoolContent2 from "../components/SchoolContent2";
import SchoolContent3 from "../components/SchoolContent3";
import SchoolContent4 from "../components/SchoolContent4";
import SchoolContent5 from "../components/SchoolContent5";
import SchoolContent6 from "../components/SchoolContent6";
import SchoolContent7 from "../components/SchoolContent7";
import SchoolContent8 from "../components/SchoolContent8";
import SchoolContent9 from "../components/SchoolContent9";

import Footer from "../components/Footer";
import styles from "./School.module.css";

const School = () => {
  return (

    <div className={styles.home}>
      <div className={styles.hero}>
        <img className={styles.maskGroupIcon} alt="" src="/mask-group@2x.webp" />
        <img className={styles.frameIcon} alt="" src="/frame.svg" />
        <Header />
        <SchoolContent1 />
      </div>
      <SchoolContent2 />
      <SchoolContent3 />
      <SchoolContent4 />
      <SchoolContent5 />
      <SchoolContent6 />
      <SchoolContent7 />
      <SchoolContent8 />
      <SchoolContent9 />      
      <Footer />
    </div>
  );
};

export default School;
