import styles from "./SelContent6.module.css";

const SelContent6 = () => {
  return (
    <div className={styles.numbers}>
      <img className={styles.unionIcon} alt="" src="/union8.svg" />
      <div className={styles.ourProcess}>K12 Learning App</div>
      <div className={styles.steps}>

        <div className={styles.divider} />
        <div className={styles.step}>

          <img
            className={styles.illustrationIcon}
            alt=""
            src="/selimg-3.svg"
          />
        </div>

        <div className={styles.div}>
          <div className={styles.helpYourChild}>
            Anger Management
          </div>
          <div className={styles.getThemReady}>
            Kids will learn to diffuse anger in socially acceptable ways and have fulfilling relationships and careers. All just by spending 10 to 15 minutes a day to improve anger management skills.
          </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-9.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Socially Acceptable Ways</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-10.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Improved Emotional Management</span>
               </div>
            </div>
            <div className={styles.LearningAppTypeBox}>
               <div className={styles.LearningAppTypeBox1}>
                  <img
                   alt=""
                   src="/selicon-11.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Natural Emotion</span>
               </div>
               <div className={styles.LearningAppTypeBox2}>
                  <img
                   alt=""
                   src="/selicon-12.png"
                  />
                  <span className={styles.LearningAppTypeBoxText}>Mental and Physical Health</span>
               </div>
            </div>

        </div>

      </div>
    </div>
  );
};

export default SelContent6;
