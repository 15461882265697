import styles from "./SelContent11.module.css";

const SelContent11 = () => {
  
   return (
     <div className={styles.division1}>
       
          <img
            className={styles.imgdata1}
            alt=""
            src="/seldata2.svg"
          />
          <img
            className={styles.imgdata2}
            alt=""
            src="/selmethodology.svg"
          />
     </div>
   );

};

export default SelContent11;
