import styles from "./HomeContent2.module.css";
import React,{useState} from 'react'
import $ from 'jquery';

import svg1 from "../svg1.svg";
import svg from "../svg.svg";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const HomeContent2 = () => {

  const [name1, setName1] = useState(svg1);
  const [name2, setName2] = useState(svg1);
  const [name3, setName3] = useState(svg1);
  const [name4, setName4] = useState(svg1);
  const [name5, setName5] = useState(svg1);


  const changeName1 = () => {
    let value1 = name1;

    if (value1 === svg1) {
      setName1(svg);
    } else {
      setName1(svg1);
    }
  };


  const changeName2 = () => {
    let value2 = name2;

    if (value2 === svg1) {
      setName2(svg);
    } else {
      setName2(svg1);
    }
  };

  const changeName3 = () => {
    let value3 = name3;

    if (value3 === svg1) {
      setName3(svg);
    } else {
      setName3(svg1);
    }
  };


  const changeName4 = () => {
    let value4 = name4;

    if (value4 === svg1) {
      setName4(svg);
    } else {
      setName4(svg1);
    }
  };

  const changeName5 = () => {
    let value5 = name5;

    if (value5 === svg1) {
      setName5(svg);
    } else {
      setName5(svg1);
    }
  };  

    const[show1,setShow1]=useState(false);
    const[show2,setShow2]=useState(false);
    const[show3,setShow3]=useState(false);
    const[show4,setShow4]=useState(false);
    const[show5,setShow5]=useState(false);  
   
    const Accordion = styled((props: AccordionProps) => (
      <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
      border: `none`,
      
      '& .css-1betqn-MuiAccordionSummary-content .css-ahj2mt-MuiTypography-root':{
        margin:`10px 0`,
        marginLeft:`16px`,
        fontSize:`20px`,
        fontWeight: `600`,
        fontFamily: `Inter`,
       
      },
      '& .MuiAccordionSummary-content .MuiTypography-root':{
        margin:`10px 0`,
        marginLeft:`16px`,
        fontSize:`20px`,
        fontWeight: `600`,
        fontFamily: `Inter`,
       
      },
      '& .css-ahj2mt-MuiTypography-root':{
        fontFamily: `Inter`,
        fontSize:`14px`,
      
      },
      '& .MuiAccordionDetails-root':{
        fontFamily: `Inter`,
        fontSize:`14px`,
      },
      '.Mui-expanded':{
        background:`#fff`,
        borderRadius:`inherit`,
        borderBottomLeftRadius:`unset`,
        borderBottomRightRadius:`unset`,
      },
      '.MuiAccordionDetails-root':{
        background:`#fff`,
        borderRadius:`inherit`,
        borderTopLeftRadius:`unset`,
        borderTopRightRadius:`unset`,
    
      },
      '&:before': {
        backgroundColor: 'transparent',
      },
      background:`#F7F7F7`,
      borderRadius: `8px`,
      fontSize: `20px`,
      width:`inherit`,
      boxShadow:`0px 0px 0px -3px rgba(0,2,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)`,
    }));
    
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
      /*padding: theme.spacing(2),
      borderTop: '1px solid rgba(0, 0, 0, 
        x solid rgba(0, 0, 0, .125)',*/
    }));  
  return (
    <div className={styles.divParent}>

      <div className={styles.div}>

         <img className={styles.sectionChild} alt="" src="/group-2130.svg" />

        <div className={styles.thingsYourChild}>
          Things Your Child Faces in 2023
        </div>
        <div className={styles.knowWhatMajor}>
          Empowering young minds by addressing their major concerns early in life
        </div>
      </div>
      <div className={styles.section}>
        
        <div className={styles.section1}>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/homeIcon1.png"
            />
          <Typography> Pressure to perform</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The constant race to perform well leading to stress and anxiety. 
                           
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
           <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/homeIcon2.png"
            />
          <Typography>Limited access to mental health resources and stigma</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          students are facing a growing number of mental health issues such as depression, anxiety, and substance abuse, which are associated with academic and societal pressures.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
           <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/homeIcon3.png"
            />
          <Typography>Bullying and Unnecessary Peer Pressure</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Nearly one in three Indian students (32%) reported being bullied in school.60% of children in India reported experiencing some form of physical, emotional, or sexual abuse. 

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
           <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/homeIcon4.png"
            />
          <Typography>Inaccessibility to emotional development and support</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          While current education system of just intellectual learning is the focus today, Balancing emotional learnig and cognitive development is what makes someone excel in life. 

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
           <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/homeIcon5.png"
            />
          <Typography>Lack of quality personal and family time.</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
             The current economic environment limits parents' ability to provide their children with necessary attention and time, negatively impacting their mental health and well-being, with 71.2% of working parents unable to dedicate time for their children's growth. 


          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
           <img
              className={styles.soilTestingwebpIcon}
              alt=""
              src="/homeIcon6.png"
            />
          
          <Typography>Lack of risk taking ability </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The current economic environment limits parents' ability to provide their children with necessary attention and time, negatively impacting their mental health and well-being, with 71.2% of working parents unable to dedicate time for their children's growth. 


          </Typography>
        </AccordionDetails>
      </Accordion>
      
     

         </div>

      </div>

    </div>
  );
};

export default HomeContent2;
